<template>
    <div>
			<v-card>
    		<v-card-text>
          <v-alert
            border="left"
            colored-border
            color="warning"
            icon="mdi-map-marker-plus"
            elevation="2"
          >
            <v-row align="center">
              <v-col class="grow">
                No se encontró información de caja diaria para la fecha seleccionada. Es necesario confirmar la caja diaria.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="primary"
                  @click="onSubmit"
                  :loading="loading"
                >
                  <v-icon left>mdi-check</v-icon>
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
    		</v-card-text>
			</v-card>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  export default {
    name: 'CuentasCreateComponent',
    data () {
      return {
        //
      }
    },
    computed: {
      ...mapGetters('DiariosCreate', ['fecha', 'loading']),
    },
    watch: {
      //
    },
    methods: {
      ...mapActions('DiariosCreate', ['onSave']),
      // ...mapActions('DiariosPagos', { onFetchPagos: 'onFetch' }),
      // ...mapActions('DiariosBonos', { onFetchBonos: 'onFetch' }),
      ...mapMutations('DiariosCreate', ['setFecha']),
      onSubmit (event) {
        event.preventDefault()
        this.onSave().then(response => {
          this.$emit('created')
        })
      },
    },
  }
  </script>
  