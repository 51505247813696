export function generateDatosCliente(cuenta) {
  return {
    alignment: 'justify',
    columns: [
      {
        stack: [
          {
            text: [
              { text: 'Documento: ', bold: true},
              cuenta.documento
            ]
          },
          {
            text: [
              { text: 'Razon social: ', bold: true},
              cuenta.razon_social
            ]
          },
        ]
      },
      {
        stack: [
          {
            text: [
              { text: 'N° Cuenta: ', bold: true},
              cuenta.numero
            ]
          },
          {
            text: [
              { text: 'Descripcion cuenta: ', bold: true},
              cuenta.descripcion
            ]
          },
        ]
      },
    ]
  };
}
