<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
      @input="onDialogOpen"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Transaccion</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-text-field
              :value="transaccionTipo"
              readonly
              label="Fecha"
              prepend-icon="mdi-circle"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="transaccionDetalle"
              readonly
              label="Detalle"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="kilosCantidad"
              readonly
              label="KG"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="kilosPrecio"
              readonly
              label="Precio KG"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="kilosSaldo"
              readonly
              label="Saldo en KG"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="importe | formatToTwoDecimals"
              readonly
              label="Importe"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <v-footer>
              <v-btn
                color="error"
                @click="onSubmit"
                :loading="loading"
              >
                Borrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TransaccionesTiposSelect from '@/pages/debitos_tipos/SelectComponent'
import KilosInput from '@/components/KilosInput'
import InportesInput from '@/components/ImportesInput'
export default {
  name: 'TransaccionesCreateComponent',
  components: {
    'debitos-tipos-select': TransaccionesTiposSelect,
    'kilos-input': KilosInput,
    'importes-input': InportesInput
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
      focusGas: false,
    }
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    this.cuentaId = this.selectedId
    this.fecha = this.$moment()
  },
  computed: {
    ...mapGetters('Transacciones', ['edited', 'loading']),
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    transaccionTipo: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        return this.edited.transaccion_tipo
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          transaccion_tipo: value
        })
      }
    },
    transaccionDetalle: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        return this.edited.transaccion_detalle
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          transaccion_detalle: value
        })
      }
    },
    fecha: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        return this.edited.fecha ? this.$moment(this.edited.fecha) : this.$moment()
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fecha: this.$moment(value).format('YYYY-MM-DD')
        })
      }
    },
    importe: {
      get () {
        return this.edited.importe
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          importe: value
        })
      }
    },
    saldo: {
      get () {
        return parseFloat(this.edited.saldo)
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          saldo: parseFloat(value)
        })
      }
    },
    cuentaId: {
      get () {
        return this.edited.cuenta_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuenta_id: value
        })
      }
    },
    transaccionTipoId: {
      get () {
        return this.edited.transaccion_tipo_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          transaccion_tipo_id: value
        })
      }
    },
    kilosCantidad: {
      get () {
        return this.edited.kilos_cantidad
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_cantidad: value
        })
      }
    },
    kilosPrecio: {
      get () {
        return this.edited.kilos_precio
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_precio: value
        })
      }
    },
    kilosSaldo: {
      get () {
        return this.edited.kilos_saldo
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_saldo: value
        })
      }
    },
    createdAt: {
      get () {
        return this.edited.created_at
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          created_at: value
        })
      }
    },
    updatedAt: {
      get () {
        return this.edited.updated_at
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          updated_at: value
        })
      }
    },
  },
  watch: {
    transaccionTipoId (newValue) {
      if (newValue && newValue.id > 0) {
        this.focusGas = true;
        // Reiniciar después de que el campo gane foco
        this.$nextTick(() => {
          this.focusGas = false;
        });
      }
    },
    selectedId (newValue) {
      this.cuentaId = newValue
    },
    kilosCantidad (newValue) {
      // console.log(newValue)
    },
  },
  methods: {
    ...mapActions('Cuentas', ['onRefresh']),
    ...mapActions('Transacciones', ['onFetch']),
    ...mapActions('Transacciones', ['onDelete', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Transacciones', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onDelete().then(response => {
          this.onFetch()
          this.onRefresh()
          this.dialog = false
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    handleKeyboard (event) {
      // if(event.key === "Enter" && !event.ctrlKey) {
      //   this.onSubmit(event)
      // }
      // else if(event.altKey && event.key == "k") {
      //   event.preventDefault()
      //   this.focusGas = true
      // }
    },
    onDialogOpen () {
      // Esperamos a que el diálogo esté completamente abierto
      this.$nextTick(() => {
        if (this.dialog) {
          window.addEventListener('keydown', this.handleKeyboard);
        }
        else {
          window.removeEventListener('keydown', this.handleKeyboard);
          this.onClearEdited();
          this.onClearSelected();
        }
      });
    },
  },
}
</script>
