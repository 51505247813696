<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      absolute
      style="max-width: 600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item
            :to="{ name: 'formas-pagos-main' }"
            @click="closeMenu"
          >
            <v-list-item-icon>
              <v-icon>mdi-credit-card</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Formas de pago</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('productos.index')"
            :to="{ name: 'productos-main' }"
            @click="closeMenu"
          >
            <v-list-item-icon>
              <v-icon>mdi-propane-tank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Productos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('productos.index')"
            :to="{ name: 'precios-vigentes-main' }"
            @click="closeMenu"
          >
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Precios vigentes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="closeMenu"
          >
            <v-list-item-avatar>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Usuarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menu: false,
    }
  },
  methods: {
    closeMenu (event) {
      event.preventDefault()
      this.menu = false;
    },
  },
}
</script>