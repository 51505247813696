<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de cierres diarios para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'DiariosPagosDatatableComponent',
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('DiariosPagos', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    },
  },
  watch: {
    filters () {
      this.onFetch()
    }
  },
  methods: {
    ...mapActions('DiariosPagos', ['onFetch']),
    ...mapMutations('DiariosPagos', ['setOptions']),
  }
}
</script>
