<template>
  <div>
    <v-select
      label="Producto"
      v-model="selectedLocal"
      item-text="nombre"
      item-value="id"
      :items="all"
      prepend-icon="mdi-propane-tank"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ProductosSelectComponent',
  model: {
    prop: 'productoid',
    event: 'change'
  },
  props: {
    productoid: {
      type: Number,
      default: null
    },
  },
  async created () {
    await this.onEstablecerDB();
    await this.onEstablecerDefault();
  },
  computed: {
    ...mapGetters('Productos', ['all']),
    selectedLocal: {
      get () {
        return this.productoid
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  methods: {
    ...mapActions('Productos', ['onFetch']),
    ...mapMutations('Productos', ['setAll']),
    async onEstablecerDB () {
      // Verifica si los productos ya están cargados
      if (this.all?.length) return;
      try {
        const aux = this.getFromSession() || await this.onFetch();
        if (aux) this.setAll(aux);
      } catch (error) {
        console.error('Error al cargar los productos:', error);
      }
    },
    onEstablecerDefault () {
      const aux = this.all[0]?.id;
      this.$emit('change', aux)
    },
    getFromSession () {
      const aux = window.sessionStorage.getItem('pro');
      return aux ? JSON.parse(aux) : null;
    }
  }
}
</script>
