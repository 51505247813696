<template>
  <div>
    <v-text-field
      ref="textField"
      :value="formateado | formatToTwoDecimals" 
      prepend-icon="mdi-currency-usd"
      :label="label"
      :disabled="disabled"
      :readonly="readonly"
      @change="onChange"
      @keydown="onKeydown"
    ></v-text-field>
  </div>
</template>

<script>
export default {
      // @blur="onBlur"
  name: 'ImporteInputComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: 0.0,
    },
    label: {
      type: String,
      default: 'Importe'
    },
    rules: Array,
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    conversor: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formateado: '0,00'
    }
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  watch: {
    focus (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.textField.focus();
        });
      }
    },
    selectedLocal (newValue) {
      //
    },
  },
  methods: {
    onChange (event) {
      const inputValue = event;
      this.onActualizar(inputValue)
    },
    // onBlur (event) {
    //   event.preventDefault();
    //   if (this.formateado && this.formateado.length > 0) {
    //     const formateadoAux = parseFloat(this.formateado.replace(',', '.')); // Reemplaza comas por puntos
    //     if (!isNaN(formateadoAux)) {
    //       this.formateado = this.formatearNumero(formateadoAux);
    //       this.selectedLocal = formateadoAux;
    //     }
    //   }
    // },
    onKeydown (event) {
      const key = event.key;
      const isDigit = key >= '0' && key <= '9';
      const isCommaOrDot = key === ',' || key === '.';
      const isControlKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key); // Teclas de control permitidas
      // Obtén el valor actual del campo de texto
      const currentValue = event.target.value;
      // Verifica si ya hay un punto o una coma en el texto
      const alreadyHasDecimal = currentValue.includes(',') || currentValue.includes('.');
      // Si ya hay un punto o una coma y el usuario intenta ingresar otro, prevenir el evento
      if (isCommaOrDot && alreadyHasDecimal) {
        event.preventDefault();
      }
      // Si no es un dígito, coma/punto permitido o una tecla de control, prevenir el evento
      if (!isDigit && !isCommaOrDot && !isControlKey) {
        event.preventDefault();
      }
    },
    formatearNumero (value) {
      // Asegúrate de que el valor sea un número
      const numero = parseFloat(value);
      if (isNaN(numero)) return ''; // Si no es un número, retorna una cadena vacía
      const partes = numero.toFixed(2).split('.');
      partes[0] = partes[0].replace(/\B(?=(\d{2})+(?!\d))/g, '.'); // Agregar puntos como separadores de miles
      return partes.join(',');
    },
    onActualizar (inputValue) {
      // Expresión regular que permite solo números enteros o decimales con hasta 2 dígitos después del punto
      const regex = /^\d+([,.]\d{0,2})?$/;
      // Reemplaza comas por puntos para que parseFloat funcione correctamente
      const valorConPunto = inputValue.replace(',', '.');
      // Verifica que el valor cumpla con la expresión regular
      if (regex.test(valorConPunto)) {
        this.formateado = valorConPunto; // Actualiza el valor formateado
        this.selectedLocal = parseFloat(valorConPunto); // Convierte el valor a número
      } else {
        // Si el valor no es válido, puedes limpiar el campo o mostrar un mensaje (opcional)
        console.log('Entrada inválida: solo se permiten números decimales con hasta 2 dígitos decimales');
      }
    },
  }
}
</script>
