<template>
  <div>
    <v-menu
      offset-x
      absolute
      v-model="showMenu"
      style="max-width: 600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark left>mdi-store-marker</v-icon>
          {{ selectedLocal && selectedLocal.abreviatura ? selectedLocal.abreviatura : '---'}}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in all"
          :key="index"
          @click="onSelect(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ selectedLocal.id === item.id ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.abreviatura }}. {{ item.nombre }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'SucursalesSelectComponent',
  data () {
    return {
      showMenu: false,
    }
  },
  created () {
    this.onEstablecerDB()
  },
  computed: {
    ...mapGetters('Sucursales', ['all', 'selected']),
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        const aux = value && value.id > 0 ? value.id : null
        window.sessionStorage.setItem('sucid', JSON.stringify(aux))
        this.setSelected(value)
        // this.$emit('update:sucursal_id', value ? value.id : null)
        this.$root.$emit('update:sucursal_id', value ? value.id : null)
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Sucursales', ['onFetch', 'onClearSelected']),
    ...mapMutations('Sucursales', ['setSelected', 'setAll']),
    onEstablecerDB () {
      // Lista de sucursales
      const suc = this.getSessionStorageItem('suc')
      if (suc) {
        // Se establece la lista de sucursales almacenada localmente, como lista en el store
        this.setAll(suc)
      }
      else {
        // Se busca una lista de sucursales y se almacena localmente
        // No es necesario guardar con setAll por que el metodo onFetch lo hace internamente
        this.onFetch().then((response) => {
          this.setSessionStorageItem('suc', this.all)
          const aux = this.all[0]
          this.setSelected(aux)
          this.setSessionStorageItem('sucid', aux.id)
        }).catch(error => {
          // Puedes mostrar una notificación al usuario o realizar una acción alternativa
          console.error('Error al cargar las sucursales:', error)
        })
      }
      // Se recupera el valor de sucursal_id seleccionado
      const sucid = this.getSessionStorageItem('sucid')
      if (sucid > 0) {
        // Se busca el seleccionado y se establece en el store
        this.setSelected(this.all.find(item => item.id === sucid))
      }
    },
    getSessionStorageItem(key) {
      try {
        return JSON.parse(window.sessionStorage.getItem(key))
      } catch (e) {
        return null
      }
    },
    setSessionStorageItem(key, value) {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    onSelect (item) {
      if (item && item.id) {
        this.selectedLocal = item
      }
    }
  }
}
</script>
