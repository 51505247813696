const URL = '/clientes';

const state = {
  all: [],
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: null,
    ADM_cliente_id: null,
    DIS_cliente_id: null,
    created_at: null,
    updated_at: null
  },
  edited: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: null,
    ADM_cliente_id: null,
    DIS_cliente_id: null,
    created_at: null,
    updated_at: null
  },
  default: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: null,
    ADM_cliente_id: null,
    DIS_cliente_id: null,
    created_at: null,
    updated_at: null
  },
  filters: {
    searchText: null,
    documento: null,
    razon_social: null,
    solo_inactivos: false,
    cliente_id: null
  },
  loading: false,
  headers: [
    {
      text: 'Documento',
      value: 'documento',
      align: 'center'
    },
    {
      text: 'Apellido y nombre',
      value: 'razon_social',
      align: 'center'
    },
    {
      text: 'Inactivo',
      value: 'inactivo',
      align: 'center'
    },
  ],
  rules: {
    documento: [
      v => !!v || 'Este campo es necesario',
    ],
    razon_social: [
      v => !!v || 'Este campo es necesario',
    ],
    inactivo: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  options: {
    page: 1,
    itemsPerPage: 10
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  isSelected (state) {
    return state.edited.id > 0
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
            state.edited.documento != state.selected.documento ||
            state.edited.razon_social != state.selected.razon_social ||
            state.edited.inactivo != state.selected.inactivo
          )
  },
  filterable (state) {
    return (state.filters.documento ||
      state.filters.razon_social ||
      state.filters.inactivos
    )
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
    commit('setItemsLength', 0)
    commit('setOptions', {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: []
    })
  },
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearFilters ({ state, commit }) {
    commit('setFilters', {
      searchText: null,
      documento: null,
      razon_social: null,
      inactivos: null,
      cliente_id: null
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          searchText: state.filters.searchText,
          documento: state.filters.documento,
          razon_social: state.filters.razon_social,
          inactivo: state.filters.inactivo,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + state.filters.cliente_id)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.id,
            documento: response.data.documento,
            razon_social: response.data.razon_social,
            inactivo: response.data.inactivo,
            ADM_cliente_id: response.data.ADM_cliente_id,
            DIS_cliente_id: response.data.DIS_cliente_id,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.data.id,
            documento: response.data.data.documento,
            razon_social: response.data.data.razon_social,
            inactivo: response.data.data.inactivo,
            ADM_cliente_id: response.data.data.ADM_cliente_id,
            DIS_cliente_id: response.data.data.DIS_cliente_id,
            created_at: response.data.data.created_at,
            updated_at: response.data.data.updated_at
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Información del cliente eliminada', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. Información del cliente no eliminada', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
