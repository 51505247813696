<template>
  <div>
    <v-navigation-drawer
      permanent
      app
    >
      <v-toolbar class="primary">
      </v-toolbar>
      <v-list
        nav
      >
        <v-list-item
          dark
          class="primary"
          @click="onNuevoCliente"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo cliente</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Clientes vigentes</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-table-large</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!--
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Clientes inactivos</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-table-large</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Exportaciones</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>
        </v-list-item>
        -->
      </v-list>
    </v-navigation-drawer>

    <!-- CREATE -->
    <clientes-create
      v-model="dialogCreate"
    >
    </clientes-create>
    <!--
      * menu buscar / menu cambiar
      * razon social
      * menu usuario
    -->

    <!-- FILTROS -->
    <clientes-filtros />
    <!-- 
      * Fecha desde
      * Fecha hasta
      * Kilos
      * Formas de pago
      * servicios
     -->
    <!-- CLIENTES -->
    <clientes-datatable
      @update:selected="onSelect"
    >
    </clientes-datatable>
    <!-- EXPORTACIONES -->
    <clientes-exportaciones />
    <clientes-listener />
    <clientes-form v-model="dialogForm"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ClientesFilters from '@/pages/clientes/Filters'
import ClientesDatatable from '@/pages/clientes/Datatable'
import ClientesCreate from '@/pages/clientes/CreateComponent'
import ClientesListener from '@/pages/clientes/ListenerComponent'
import ClientesExports from '@/pages/clientes/ExportsComponent'
import ClientesForm from '@/pages/clientes/FormComponent'

export default {
  components: {
    'clientes-filtros': ClientesFilters,
    'clientes-datatable': ClientesDatatable,
    'clientes-create': ClientesCreate,
    'clientes-listener': ClientesListener,
    'clientes-exportaciones': ClientesExports,
    'clientes-form': ClientesForm,
  },
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
      dialogCreate: false,
      dialogForm: false,
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    window.addEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    onNuevoCliente (event) {
      event.preventDefault()
      this.dialogCreate = true
    },
    onSelect () {
      // event.preventDefault()
      this.dialogForm = true
    },
    handleKeyboard(event) {
      const actions = {
        F1: this.onNuevoCliente,
      };
      if (!event.ctrlKey && actions[event.key]) {
        event.preventDefault()
        actions[event.key].call(this, event);
      }
    }
  },
}
</script>
