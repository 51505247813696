<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450px"
      transition="dialog-transition"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos de la cuenta</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-text-field
              v-model="numero"
              placeholder="Ingrese un número de cuenta"
              label="Número de cuenta"
              :rules="rules.numero"
              prepend-icon="mdi-card-account-details"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="descripcion"
              placeholder="Ingrese descripcion de la cuenta"
              label="Descripcion"
              :rules="rules.descripcion"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="saldo"
              placeholder="Ingrese el saldo de la cuenta"
              label="Saldo"
              :rules="rules.saldo"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
            <!-- KILOS SUBSIDIADOS -->
            <kilos-input
              v-model="kiloSubsidiados"
              placeholder="Ingrese los kg subsidiados"
              label="KG subsidiados"
            ></kilos-input>
            <!--
            <v-text-field
              v-model="kiloSubsidiados"
              placeholder="Ingrese los kg subsidiados"
              label="KG subsidiados"
              :rules="rules.kilos_subsidiados"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            -->
            <kilos-input
              v-model="kiloDisponibles"
              placeholder="Ingrese los kg disponibles"
              label="KG disponibles"
            ></kilos-input>
            <!--
            <v-text-field
              v-model="kiloDisponibles"
              placeholder="Ingrese los kg disponibles"
              label="KG disponibles"
              :rules="rules.kilos_disponibles"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            -->

            <v-switch
              v-model="switchCierre"
              label="Cierre. Se deshabiltan las operaciones sobre la cuenta y se establece una fecha de cierre."
            ></v-switch>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Actualizar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import KilosInput from '@/components/KilosInput'
export default {
  name: 'CuentasEditComponent',
  components: {
    'kilos-input': KilosInput
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      valid: false,
    }
  },
  beforeDestroy () {
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Cuentas', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    saldo: {
      get () {
        return this.edited.saldo
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          saldo: value
        })
      }
    },
    kiloSubsidiados: {
      get () {
        return this.edited.kilos_subsidiados
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_subsidiados: value
        })
      }
    },
    kiloDisponibles: {
      get () {
        return this.edited.kilos_disponibles
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_disponibles: value
        })
      }
    },
    switchCierre: {
      get () {
        return this.edited.cierre ? true : false
      },
      set (value) {
        var cierreAux = null
        if (value === true) cierreAux = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.setEdited({
          ...this.edited,
          cierre: cierreAux
        })
      }
    },
  },
  methods: {
    ...mapActions('Cuentas', ['onUpdate', 'onFind', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Cuentas', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(response => {
          this.$emit('change', this.edited.id)
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    }
  },
}
</script>
