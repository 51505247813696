<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Precios vigentes</v-toolbar-title>
    </v-toolbar>
    <v-sheet>
      <v-container>
        <v-list>
          <v-subheader>DESCARGA DE BONOS</v-subheader>
          <v-list-item
            v-for="(item, index) in bonos"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.vigencia | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
              <v-list-item-title>{{ item.modo }} $ {{ item.importe | formatToTwoDecimals }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet>
      <v-container>
        <v-list>
          <v-subheader>VENTAS</v-subheader>
          <v-list-item
            v-for="(item, index) in debitos"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.vigencia | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
              <v-list-item-title>{{ item.modo }} $ {{ item.importe | formatToTwoDecimals }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet>
      <v-container>
        <v-list>
          <v-subheader>PAGOS</v-subheader>
          <v-list-item
            v-for="(item, index) in creditos"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.vigencia | moment('DD/MM/YYYY') }}</v-list-item-subtitle>
              <v-list-item-title>{{ item.modo }} $ {{ item.importe | formatToTwoDecimals }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'PreciosVigentesComponent',
  async created () {
    await this.onEstablecerDB();
  },
  computed: {
    ...mapGetters('PreciosVigentes', ['bonos', 'creditos', 'debitos']),
  },
  methods: {
    ...mapMutations('PreciosVigentes', ['setBonos', 'setCreditos', 'setDebitos']),
    async onEstablecerDB () {
      // Verifica si los precios de los bonos ya están cargados
      if (this.bonos?.length) return;
      try {
        const bonos = this.getBonosFromSession();
        if (bonos) this.setBonos(bonos);
      } catch (error) {
        console.error('Error al cargar los precios de los bonos:', error);
      }
      // Verifica si los precios de los creditos ya están cargados
      if (this.creditos?.length) return;
      try {
        const creditos = this.getCreditosFromSession();
        if (creditos) this.setCreditos(creditos);
      } catch (error) {
        console.error('Error al cargar los precios de los creditos:', error);
      }
      // Verifica si los precios de los debitos ya están cargados
      if (this.debitos?.length) return;
      try {
        const debitos = this.getDebitosFromSession();
        if (debitos) this.setDebitos(debitos);
      } catch (error) {
        console.error('Error al cargar los precios de los debitos:', error);
      }
    },
    getBonosFromSession () {
      const aux = window.sessionStorage.getItem('bon');
      return aux ? JSON.parse(aux) : null;
    },
    getCreditosFromSession () {
      const aux = window.sessionStorage.getItem('cre');
      return aux ? JSON.parse(aux) : null;
    },
    getDebitosFromSession () {
      const aux = window.sessionStorage.getItem('deb');
      return aux ? JSON.parse(aux) : null;
    }
  }
}
</script>
