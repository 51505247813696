<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'TransaccionesListenerComponent',
  data () {
    return {
      //
    }
  },
  created () {
    if (this.cuentaId) {
      this.setFilters({
        ...this.filters,
        cuenta_id: this.cuentaId
      })
      this.onFetch()
    }
  },
  computed: {
    ...mapGetters('Cuentas', ['selected', 'filters']),
    cuentaSelected () {
      return this.selected && this.selected.id > 0
    },
    cuentaId () {
      return this.selected && this.selected.id > 0 ? this.selected.id : null
    },
  },
  watch: {
    // cuentaId (newVal) {
    //   if (newVal) {
    //     this.setFilters({
    //       ...this.filters,
    //       cuenta_id: newVal
    //     })
    //     this.onFetch()
    //   }
    // }
  },
  methods: {
    ...mapActions('Transacciones', ['onFetch']),
    ...mapMutations('Transacciones', ['setFilters']),
  }
}
</script>
