const URL = '/auth';

const state = {
  edited: {
    name: null,
    email: null,
    password: null
  },
  permisos: [],
  errors: [],
  forbidden: false,
  loading: false,
  validations: {
    //
  },
  edited: {
    name: null,
    email: null,
    password: null
  },
}

const getters = {
  edited (state) {
    return state.edited
  },
  forbidden (state) {
    return state.forbidden
  },
  rules (state) {
    return state.validations
  },
  permisos (state) {
    return state.permisos
  },
  loading (state) {
    return state.loading
  },
}

const actions = {
  onLogin ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL + '/login', {
          email: state.edited.email,
          password: state.edited.password
        })
        .then(response => {
          const resAux = response.data
          commit('setToken', resAux.access_token)
          // commit('setExpiresIn', resAux.expires_in)
          resolve(response.data)
          // dispatch('onPermisos')
          //   .then(response => {
          //     resolve(response.data)
          //   })
          //   .catch(error => {
          //     commit('setErrors', error.data)
          //     reject(error)
          //   })
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onMe ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/me')
        .then(response => {
          const resAux = response.data
          commit('setEdited', {
            ...state.edited,
            name: resAux.name,
            email: resAux.email
          })
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onRefresh ({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL + '/refresh')
        .then(response => {
          commit('setToken', response.data.access_token)
          // commit('setExpiresIn', response.data.expires_in)
          dispatch('onPermisos')
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

  onPermisos ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/permissions')
        .then(response => {
          commit('setPermisos', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          commit('setPermisos', null)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

  onLogout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL + '/logout')
        .then(response => {
          // commit('setToken', null)
          // commit('setPermisos', null)
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// mutations
const mutations = {
  setEdited (state, payload) {
    state.edited = Object.assign(state.edited, payload)
  },
  setToken (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('token')
    } else {
      window.sessionStorage.setItem('token', payload)
    }
  },
  // setName (state, payload) {
  //   if (_.isNil(payload)) {
  //     window.sessionStorage.removeItem('name')
  //   } else {
  //     window.sessionStorage.setItem('name', payload)
  //   }
  //   state.name = payload
  // },
  // setEmail (state, payload) {
  //   if (_.isNil(payload)) {
  //     window.sessionStorage.removeItem('email')
  //   } else {
  //     window.sessionStorage.setItem('email', payload)
  //   }
  //   state.email = payload
  // },
  // setExpiresIn (payload) {
  //   if (_.isNil(payload)) {
  //     window.sessionStorage.removeItem('expires_in')
  //   } else {
  //     window.sessionStorage.setItem('expires_in', payload)
  //   }
  // },
  setPermisos (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('permissions')
    } else {
      window.sessionStorage.setItem('permissions', JSON.stringify(payload.slice()))
    }
    state.permisos = payload.slice()
  },
  // setPermisos (state, payload) {
  //   if (_.isNil(payload)) {
  //     window.sessionStorage.removeItem('permissions')
  //   } else {
  //     window.sessionStorage.setItem('permissions', payload.slice())
  //   }
  //   state.permisos = payload.slice()
  // },
  setLoading (state, payload) {
    state.loading = payload
  },
  setErrors (state, payload) {
    state.errors = payload
  },
  setForbidden (state, payload) {
    state.forbidden = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
