const URL = '/diarios_bonos';

const state = {
  all: [],
  selected: {
    id: null,
    fecha: null,
    kilos: null,
    importe: null,
    sucursal_id: null,
    user_id: null,
    created_at: null,
    updated_at: null
  },
  edited: {
    id: null,
    fecha: null,
    kilos: null,
    importe: null,
    sucursal_id: null,
    user_id: null,
    created_at: null,
    updated_at: null
  },
  filters: {
    searchText: null,
    fecha_from: null,
    fecha_to: null,
    user_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Forma de pago',
      value: 'forma_pago',
      align: 'center'
    },
    {
      text: 'Total Importe',
      value: 'importe',
      align: 'center'
    },
    {
      text: 'Total KG',
      value: 'kilos',
      align: 'center'
    },
  ],
  rules: {
    fecha: [
      // v => !!v || 'Este campo es necesario',
    ],
    kilos: [
      // v => !!v || 'Este campo es necesario',
    ],
    importe: [
      // v => !!v || 'Este campo es necesario',
    ],
    sucursal_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    user_id: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  options: {
    itemsPerPage: 10,
    page: 1,
    sortBy: [],
    sortDesc: []
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.fecha != state.selected.fecha ||
      state.edited.kilos != state.selected.kilos ||
      state.edited.importe != state.selected.importe ||
      state.edited.sucursal_id != state.selected.sucursal_id ||
      state.edited.user_id != state.selected.user_id
    )
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
    commit('setOptions', {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: []
    })
  },
  onClearEdited ({ commit}) {
    commit('setEdited', {
      id: null,
      fecha: null,
      kilos: null,
      importe: null,
      sucursal_id: null,
      user_id: null,
      created_at: null,
      updated_at: null
    })
  },
  onClearSelected ({ commit}) {
    commit('setSelected', {
      id: null,
      fecha: null,
      kilos: null,
      importe: null,
      sucursal_id: null,
      user_id: null,
      created_at: null,
      updated_at: null
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          searchText: state.filters.searchText,
          fecha_from: state.filters.fecha_from,
          fecha_to: state.filters.fecha_to,
          user_id: state.filters.user_id,
          export: 0
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Caja diaria registrada', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se registro la caja diaria', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'La caja diaria fue borrada.', { root: true })
          dispatch('onClearEdited')
          dispatch('onClearSelected')
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se borro la caja diaria.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
