<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Cuentas activas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="onSubmitFetch"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.kilos_subsidiados="{ item }">
        <div v-if="item.kilos_subsidiados">
          {{ item.kilos_subsidiados + ' Kg' }}
        </div>
      </template>
      <template v-slot:item.kilos_disponibles="{ item }">
        <div v-if="item.kilos_disponibles">
          {{ item.kilos_disponibles + ' Kg' }}
        </div>
      </template>
      <template v-slot:item.saldo="{ item }">
        <div v-if="item.saldo">
          $ {{ item.saldo }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de cuentas para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    clienteId: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    this.onBuscarCuentas(this.clienteId)
  },
  computed: {
    ...mapGetters('CuentasActivas', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        // if (this.clienteId > 0) this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
  },
  watch: {
    clienteId (newValue) {
      this.onBuscarCuentas(newValue)
    }
  },
  methods: {
    ...mapActions('CuentasActivas', ['onFetch']),
    ...mapMutations('CuentasActivas', ['setFilters', 'setOptions', 'setEdited', 'setSelected']),
    onSubmitFetch (event) {
      event.preventDefault()
      this.onFetch()
    },
    onBuscarCuentas (clienteId) {
      this.setFilters({
        ...this.filters,
        cliente_id: clienteId
      })
      if (clienteId > 0) this.onFetch()
    },
    handleKeyboard(event) {
      // if(event.key === "F1" && !event.ctrlKey) {
      //   event.preventDefault()
      // }
    },
  }
}
</script>
