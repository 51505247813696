<template>
  <div>
    <v-list-item
      :disabled="!cuentaSelected"
      @click="onSubmit"
    >
      <v-list-item-icon>
        <v-icon>mdi-keyboard-f4</v-icon>
      </v-list-item-icon>
      <v-list-item-title>Imprimir deuda</v-list-item-title>
      <v-list-item-icon>
        <v-icon>mdi-printer</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { generateEncabezado } from '@/pages/transacciones/pdf_encabezado.js';
import { generateDatosCliente } from '@/pages/transacciones/pdf_datos_cliente.js';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.vfs;

export default {
  name: 'ResumenPrintComponent',
  data () {
    return {
      loading: false,
      qrPath: 'public/images/qr.png',
      empresa: {
        razon_social: 'SARTINI GAS S.R.L.',
        cuit: '30-59748997-4',
        ing_brutos: '101926A',
        domicilio: 'Heroes de malvinas 4620',
        localidad: 'Ushuaia - Tierra del Fuego',
        actividades: '01/01/1983',
        condicion_iva: 'IVA Responsable Inscripto'
      },
    }
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    ...mapGetters('Transacciones', { transacciones: 'all' }),
    ...mapGetters('Cuentas', { cuenta: 'edited' }),
    cuentaSelected () {
      return this.cuenta && this.cuenta.id > 0
    },
  },
  methods: {
    async loadImageAsBase64 () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        fetch(this.qrPath)
          .then(response => response.blob())
          .then(blob => reader.readAsDataURL(blob))
          .catch(error => {
            console.error('Error al cargar la imagen:', error);
            reject(error);
          });
      });
    },
    async onSubmit () {
      try {
        this.loading = true;
        // Verificamos la ruta del QR
        if (!this.qrPath) {
          console.warn('Ruta del QR no definida, el PDF se generará sin QR.');
        }
        // Esperamos la conversión de la imagen a Base64
        let base64Image = '';
        // if (this.qrPath) {
        //   try {
        //     base64Image = await this.loadImageAsBase64();
        //   } catch (error) {
        //     console.warn('No se pudo cargar la imagen QR, el PDF se generará sin QR.');
        //   }
        // }

        const fecha = this.$moment().format('DD/MM/YYYY');
        const encabezado = generateEncabezado(this.empresa);
        const datosCliente = generateDatosCliente(this.cuenta);
        const datosTransacciones = {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              ['Fecha', 'Descripción', 'Precio', 'Cantidad', 'Importe', 'Saldo'],
              ...this.transacciones.map((item) => [
                item.fecha ? this.$moment(item.fecha).format('DD/MM/YYYY') : '',
                item.transaccion_detalle || 'Sin descripción',
                `$ ${item.kilos_precio ? item.kilos_precio : '0.00'}`,
                item.kilos_cantidad || '0',
                `$ ${item.transaccion_tipo === 'Debito' ? '-' : ''} ${item.importe ? item.importe : '0.00'}`,
                `$ ${item.saldo ? item.saldo : '0.00'}`,
              ]),
            ],
          },
          margin: [0, 5, 0, 10],
        };

        const docDefinition = {
          content: [
            encabezado,
            { text: 'Datos del cliente', style: 'subheader' },
            datosCliente,
            { text: 'Saldo actual', style: 'subheader' },
            '$ ' + this.cuenta.saldo,
            { text: 'Detalle', style: 'subheader' },
            this.transacciones && this.transacciones.length > 0 ? datosTransacciones : 'No hay transacciones.',
          ],
          footer: (currentPage, pageCount) => ({
            columns: [
              // { text: `Página ${currentPage} de ${pageCount}`, alignment: 'left', margin: [10, 5, 0, 0] },
              // base64Image
              //   ? {
              //       image: base64Image,
              //       width: 60, // Ajusta el tamaño del QR
              //       alignment: 'right',
              //       margin: [0, 5, 10, 0],
              //     }
                // : '', // No se muestra nada si no hay QR disponible
            ],
          }),
          styles: {
            header: { fontSize: 18, bold: true },
            subheader: { fontSize: 15, bold: true, margin: [0, 10, 0, 5] },
            sectionTitle: { fontSize: 12, bold: true, margin: [0, 10, 0, 5] },
            footer: { fontSize: 10, italics: true, margin: [0, 20, 0, 0] },
          },
          images: {
            snow: 'https://criticasur.com.ar/uploads/noticias/5/20170615191830_sartini_gas.jpg',
            // snow: {
              // //
            // }
          }
        };
        const filename = this.cuenta.documento + '_' + this.cuenta.numero + '_resumen.pdf';
        // Generar y descargar el PDF
        pdfMake.createPdf(docDefinition).download(filename);
      } catch (error) {
        console.error('Error al generar el PDF:', error);
      } finally {
        this.loading = false;
      }
    },
    handleKeyboard (event) {
      const actions = {
        F4: this.onSubmit,
      };

      if (!event.ctrlKey && this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        actions[event.key].call(this, event);
      }
      else if (!event.ctrlKey && !this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        alert('Primero debe seleccionar una cuenta!')
      }
    }
  },

}
</script>
