const URL = '/diarios_create';

const state = {
  loading: false,
  fecha: null
}

// GETTERS
const getters = {
  loading (state) {
    return state.loading
  },
  fecha (state) {
    return state.fecha
  },
}

// ACTIONS
const actions = {
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, {
        fecha: state.fecha
      })
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Caja diaria confirmada', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se confirmo la caja diaria', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setFecha (state, payload) {
    state.fecha = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
