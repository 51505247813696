<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      width="350"
    >
      <v-toolbar flat>
        <v-subheader>Datos de la cuenta</v-subheader>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="onCuentaEdit"
          :disabled="!cuentaSelected"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list
        nav
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Número</v-list-item-subtitle>
            <v-list-item-title>{{ numero }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Descripción</v-list-item-subtitle>
            <v-list-item-title>{{ descripcion }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Saldo</v-list-item-subtitle>
            <v-list-item-title>{{ saldo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge-full</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Subsidiado verano</v-list-item-subtitle>
            <v-list-item-title>{{ kilos_verano }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge-full</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Subsidiado invierno</v-list-item-subtitle>
            <v-list-item-title>{{ kilos_invierno }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>KG disponibles</v-list-item-subtitle>
            <v-list-item-title>{{ kilos_disponibles }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              v-if="parseFloat(kilos_disponibles) < 0"
              class="error--text"
            >
              mdi-alert-circle-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
        nav
      >
        <v-list-item
          :class="cuentaSelected ? 'primary' : 'grey'"
          dark
          @click="onDescargaBono"
          :disabled="!cuentaSelected"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Descarga bono</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          :class="cuentaSelected ? 'primary' : 'grey'"
          dark
          @click="onNuevoDebito"
          :disabled="!cuentaSelected"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nueva venta</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          :class="cuentaSelected ? 'primary' : 'grey'"
          dark
          @click="onNuevoCredito"
          :disabled="!cuentaSelected"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo pago</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!--
        <v-list-item
          @click="onImprimirDeuda"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f4</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Imprimir deuda</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-printer</v-icon>
          </v-list-item-icon>
        </v-list-item>
        -->
        <resumen-print />
      </v-list>
    </v-navigation-drawer>
    
    <cuentas-edit v-model="dialogEditCuenta" />
    <debitos-create v-model="dialogCreateDebito" />
    <creditos-create v-model="dialogCreateCredito" />
    <bonos-create v-model="dialogCreateBono" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ResumenPrint from '@/pages/cuentas/PrintComponent'
import CuentasEdit from '@/pages/cuentas/EditComponent'
import DebitosCreate from '@/pages/debitos/CreateComponent'
import CreditosCreate from '@/pages/creditos/CreateComponent'
import BonosCreate from '@/pages/bonos/CreateComponent'
export default {
  name: 'CuentasNavigationComponent',
  components: {
    'resumen-print': ResumenPrint,
    'cuentas-edit': CuentasEdit,
    'debitos-create': DebitosCreate,
    'creditos-create': CreditosCreate,
    'bonos-create': BonosCreate
  },
  data () {
    return {
      dialogEditCuenta: false,
      dialogCreateDebito: false,
      dialogCreateCredito: false,
      dialogCreateBono: false,
      drawer: true
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
    this.$root.$on('update:appbarnav', this.onUpdateAppNavBar);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.$root.$off('update:appbarnav', this.onUpdateAppNavBar);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Cuentas', ['selected']),
    cuentaSelected () {
      return this.selected && this.selected.id > 0 
    },
    numero () {
      return this.selected.numero
    },
    descripcion () {
      return this.selected.descripcion
    },
    saldo () {
      return this.selected.saldo
    },
    kilos_verano () {
      return this.selected.kilos_verano
    },
    kilos_invierno () {
      return this.selected.kilos_invierno
    },
    kilos_disponibles () {
      return this.selected.kilos_disponibles
    },
  },
  watch: {
    //
  },
  methods: {
    onDescargaBono (event) {
      event.preventDefault()
      this.dialogCreateBono = true
    },
    onNuevoDebito (event) {
      event.preventDefault()
      this.dialogCreateDebito = true
    },
    onNuevoCredito (event) {
      event.preventDefault()
      this.dialogCreateCredito = true
    },
    onImprimirSaldo (event) {
      event.preventDefault()
      alert('Imprimir saldo!')
    },
    onImprimirDeuda (event) {
      event.preventDefault()
      alert('Imprimir deuda!')
    },
    onCuentaEdit (event) {
      event.preventDefault()
      this.dialogEditCuenta = true
    },
    handleKeyboard(event) {
      const actions = {
        F1: this.onDescargaBono,
        F2: this.onNuevoDebito,
        F3: this.onNuevoCredito,
      };

      if (!event.ctrlKey && this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        actions[event.key].call(this, event);
      }
      else if (!event.ctrlKey && !this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        alert('Primero debe seleccionar una cuenta!')
      }
    },
    onUpdateAppNavBar () {
      this.drawer = !this.drawer
    },
  },
}
</script>
