<template>
  <div>
    <v-btn
      color="primary"
      @click="onSubmit"
      :loading="loading"
    >
      <v-icon left>mdi-printer</v-icon>
      Imprimir
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { generateEncabezado } from '@/pages/transacciones/pdf_encabezado.js';
import { generateDatosCliente } from '@/pages/transacciones/pdf_datos_cliente.js';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.vfs;

export default {
  name: 'TransaccionPrintComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Object
  },
  data () {
    return {
      loading: false,
      qrPath: 'public/images/qr.png',
      empresa: {
        razon_social: 'SARTINI GAS S.R.L.',
        cuit: '30-59748997-4',
        ing_brutos: '101926A',
        domicilio: 'Heroes de malvinas 4620',
        localidad: 'Ushuaia - Tierra del Fuego',
        actividades: '01/01/1983',
        condicion_iva: 'IVA Responsable Inscripto'
      },
    }
  },
  computed: {
    ...mapGetters('Transacciones', { transaccion: 'edited' }),
    ...mapGetters('Cuentas', { cuenta: 'edited' }),
  },
  methods: {
    async loadImageAsBase64 () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        fetch(this.qrPath)
          .then(response => response.blob())
          .then(blob => reader.readAsDataURL(blob))
          .catch(error => {
            console.error('Error al cargar la imagen:', error);
            reject(error);
          });
      });
    },
    async onSubmit () {
      try {
        this.loading = true;
        // Verificamos la ruta del QR
        if (!this.qrPath) {
          console.warn('Ruta del QR no definida, el PDF se generará sin QR.');
        }
        // Esperamos la conversión de la imagen a Base64
        let base64Image = '';
        // if (this.qrPath) {
        //   try {
        //     base64Image = await this.loadImageAsBase64();
        //   } catch (error) {
        //     console.warn('No se pudo cargar la imagen QR, el PDF se generará sin QR.');
        //   }
        // }

        const fecha = this.$moment().format('DD/MM/YYYY');
        const encabezado = generateEncabezado(this.empresa);
        const datosCliente = generateDatosCliente(this.cuenta);
        const datosTransaccion = {
          alignment: 'justify',
          columns: [
            {
              stack: [
                {
                  text: [
                    { text: 'Fecha: ', bold: true},
                    this.transaccion.fecha ? this.$moment(this.transaccion.fecha).format('DD/MM/YYYY') : ''
                  ]
                },
                {
                  text: [
                    { text: 'Total KG: ', bold: true},
                    this.transaccion.kilos_cantidad
                  ]
                },
                {
                  text: [
                    { text: 'Precio KG: $ ', bold: true},
                    this.transaccion.kilos_precio
                  ]
                },
                {
                  text: [
                    { text: 'Importe: $ ', bold: true},
                    this.transaccion.importe
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  text: [
                    { text: 'Operacion: ', bold: true},
                    this.transaccion.transaccion_detalle
                  ]
                },
                {
                  text: [
                    { text: 'Saldo KG: ', bold: true},
                    this.transaccion.kilos_saldo
                  ]
                },
                {
                  text: [
                    { text: 'Saldo: $ ', bold: true},
                    this.transaccion.saldo
                  ]
                },
              ]
            },
          ]
        };
        const docDefinition = {
          content: [
            encabezado,
            { text: 'Datos del cliente', style: 'subheader' },
            datosCliente,
            { text: 'Datos de la transaccion', style: 'subheader' },
            datosTransaccion
          ],
          footer: (currentPage, pageCount) => ({
            columns: [
              { text: `Página ${currentPage} de ${pageCount}`, alignment: 'left', margin: [10, 5, 0, 0] },
              base64Image
                ? {
                    image: base64Image,
                    width: 60, // Ajusta el tamaño del QR
                    alignment: 'right',
                    margin: [0, 5, 10, 0],
                  }
                : '', // No se muestra nada si no hay QR disponible
            ],
          }),
          styles: {
            header: { fontSize: 18, bold: true },
            subheader: { fontSize: 15, bold: true, margin: [0, 10, 0, 5] },
            sectionTitle: { fontSize: 12, bold: true, margin: [0, 10, 0, 5] },
            footer: { fontSize: 10, italics: true, margin: [0, 20, 0, 0] },
          },
          images: {
            snow: 'https://criticasur.com.ar/uploads/noticias/5/20170615191830_sartini_gas.jpg',
            // C:\Users\Usuario\Documents\Proyectos\com.sartinigas.cuentas\public\images\SARTINI_Logo.png
          }
        };
        // Generar y descargar el PDF
        pdfMake.createPdf(docDefinition).download(`cuenta.pdf`);
      } catch (error) {
        console.error('Error al generar el PDF:', error);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
