<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
      @input="onDialogOpen"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Descarga de bono</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <date-input
              v-model="fecha"
              label="Fecha"
              :max="hoy"
            ></date-input>
            <bonos-precios-select
              v-model="precioId"
              :cantidad="cantidad"
              @update:importe="onUpdateImporte"
            ></bonos-precios-select>
            <v-text-field
              v-model="cantidad"
              :disabled="!(precioId > 0)"
              label="Cantidad"
              type="number"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
              :rules="rules.cantidad"
              @keydown.enter="onSubmit"
            ></v-text-field>
            <v-text-field
              :value="importe | formatToTwoDecimals"
              readonly
              label="Importe"
              :disabled="!(precioId > 0)"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <!--
            <v-checkbox
              :disabled="!(precioId > 0)"
              v-model="checkbox"
              label="PAGO PRECIO USUARIO"
            ></v-checkbox>
            -->
            <v-checkbox
              disabled
              v-model="checkbox"
              label="PAGO PRECIO USUARIO"
            ></v-checkbox>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Registrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PreciosSelect from '@/pages/bonos/PreciosSelectComponent'
import DateInput from '@/components/DateInput'
export default {
  name: 'BonosCreateComponent',
  components: {
    'bonos-precios-select': PreciosSelect,
    'date-input': DateInput,
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
      checkbox: false,
      focusGas: false,
      hoy: null,
    }
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    this.cuentaId = this.selectedId
    this.fecha = this.$moment().format('YYYY-MM-DD')
    this.hoy = this.$moment().format('YYYY-MM-DD')
  },
  computed: {
    ...mapGetters('Cuentas', ['selectedId']),
    ...mapGetters('Bonos', ['edited', 'rules', 'loading', 'updatable']),
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    fecha: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        // return this.edited.fecha ? this.$moment(this.edited.fecha) : this.$moment()
        return this.edited.fecha
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fecha: value
          // fecha: this.$moment(value).format('YYYY-MM-DD')
        })
      }
    },
    importe: {
      get () {
        return this.edited.importe
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          importe: parseFloat(value)
        })
      }
    },
    cantidad: {
      get () {
        return this.edited.cantidad
      },
      set (value) {
        const aux = value ? parseFloat(value) : 0
        this.setEdited({
          ...this.edited,
          cantidad: aux
        })
      }
    },
    cuentaId: {
      get () {
        return this.edited.cuenta_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuenta_id: value
        })
      }
    },
    precioId: {
      get () {
        return this.edited.precio_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          precio_id: value
        })
      }
    },
  },
  watch: {
    precioId (newValue) {
      if (newValue && newValue > 0) {
        this.focusGas = true;
        // Reiniciar después de que el campo gane foco
        this.$nextTick(() => {
          this.focusGas = false;
        });
      }
    },
    selectedId (newValue) {
      this.cuentaId = newValue
    },
  },
  methods: {
    ...mapActions('Cuentas', ['onRefresh']),
    ...mapActions('Transacciones', ['onFetch']),
    ...mapActions('Bonos', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Bonos', ['setEdited']),
    onSubmit(event) {
      event.preventDefault();
      this.$refs.form.validate();
      if (this.valid) {
        this.onSave().then(response => {
          this.onFetch();
          this.onRefresh();
          this.dialog = false
        });
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    onUpdateImporte (value) {
      this.importe = value
    },
    handleKeyboard(event) {
      if(event.key === 'Enter' && !event.ctrlKey) {
        this.onSubmit(event)
      }
      else if(event.altKey && event.key == 's') {
        event.preventDefault()
        this.checkbox = !this.checkbox
      }
      else if(event.altKey && event.key == 'k') {
        event.preventDefault()
        this.focusGas = true
      }
      // else if(event.key == 'Escape' && !event.ctrlKey) {
      //   this.onClose(event)
      // }
    },
    onDialogOpen () {
      // Esperamos a que el diálogo esté completamente abierto
      this.$nextTick(() => {
        if (this.dialog) {
          window.addEventListener('keydown', this.handleKeyboard);
        }
        else {
          window.removeEventListener('keydown', this.handleKeyboard);
          this.importe = 0;
          this.cantidad = 0;
        }
      });
    },
  },
}
</script>
