<template>
  <div>
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        <v-autocomplete
          ref="autocomplete"
          v-model="select"
          :loading="loading"
          :items="all"
          item-value="id"
          @update:search-input="onSearchTextChange"
          :item-text="(item) => { return item.razon_social + ' | ' + item.documento }"
          :no-data-text="noDataText"
          placeholder="Busque una cuenta"
          flat
          solo-inverted
          @keydown.enter="onMenuBuscar"
          @change="onSelect"
          no-filter
        >
          <template v-slot:no-data>
            <v-alert
              v-if="itemsLength == 0"
              dense
              color="warning"
              border="top"
              colored-border
              icon="mdi-alert"
            >
              No hay resultados para la búsqueda. Vuelva a intentar con otro texto
            </v-alert>
            <v-alert
              v-else
              dense
              color="info"
              border="top"
              colored-border
              icon="mdi-information"
            >
              Escriba más de 2 caracteres y presione Enter...
            </v-alert>
          </template>
          <template v-slot:prepend>
            <v-btn
              icon
              @click="onMenuBuscar"
              :disabled="!searchEnabled"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <template v-slot:append-outer>
            <v-btn
              icon
              @click="onDialogCreate"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-subtitle><v-icon small left>mdi-card-account-details</v-icon>{{ data.item.documento }}</v-list-item-subtitle>
              <v-list-item-title>{{ data.item.razon_social }}</v-list-item-title>
              <v-list-item-subtitle><v-icon small left>mdi-credit-card</v-icon>{{ data.item.numero }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
    </v-card>
    <cuenta-cliente-create v-model="dialogCreate" @created="onCuentaCreated"/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ClienteCreate from '@/pages/clientes/CreateComponent'
export default {
  name: 'CuentasSelectComponent',
  components: {
    'cuenta-cliente-create': ClienteCreate,
  },
  data () {
    return {
      select: null,
      dialogCreate: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    this.$nextTick(() => {
      this.$refs.autocomplete.focus();
    });
  },
  computed: {
    ...mapGetters('Cuentas', ['all', 'edited', 'selected', 'filters', 'loading', 'itemsLength']),
    noDataText () {
      return 'Mínimo de caracteres tres (3). Luego, presione Enter...'
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    searchEnabled () {
      return this.searchText && this.searchText.length > 2
    }
  },
  methods: {
    ...mapActions('Cuentas', ['onFetch', 'onClearAll']),
    ...mapMutations('Cuentas', ['setEdited', 'setSelected', 'setFilters']),
    onSearchTextChange (val) {
      // console.log(val)
      this.searchText = val;
    },
    onMenuBuscar (event) {
      event.preventDefault()
      if (this.searchEnabled) this.onFetch()
      else alert('Debe escribir algo para buscar!')
    },
    onFocusCuenta (event) {
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.autocomplete.focus();
      });
    },
    onSelect (val) {
      const selectedItem = this.all.find(item => item.id === val);
      if (selectedItem) {
        const aux = {
          id: selectedItem.id,
          documento: selectedItem.documento,
          razon_social: selectedItem.razon_social,
          numero: selectedItem.numero,
          descripcion: selectedItem.descripcion,
          saldo: selectedItem.saldo,
          cierre: selectedItem.cierre,
          kilos_verano: selectedItem.kilos_verano,
          kilos_invierno: selectedItem.kilos_invierno,
          kilos_disponibles: selectedItem.kilos_disponibles,
          cliente_id: selectedItem.cliente_id,
          created_at: selectedItem.created_at,
          updated_at: selectedItem.updated_at
        };
        this.setEdited(aux);
        this.setSelected(aux);
        this.searchText = selectedItem.razon_social;  // Esto mantiene el nombre visible
        this.onClearAll()
      }
    },
    onDialogCreate (event) {
      event.preventDefault()
      this.dialogCreate = true
    },
    handleKeyboard (event) {
      if(event.altKey && event.key == 'n') {
        this.onDialogCreate(event)
      }
    },
    onCuentaCreated (cuenta) {
      const selectedItem = {
        id: cuenta.id,
        numero: cuenta.numero,
        descripcion: cuenta.descripcion,
        kilos_disponibles: cuenta.kilos_disponibles,
        kilos_invierno: cuenta.kilos_invierno,
        kilos_verano: cuenta.kilos_verano,
        cierre: cuenta.cierre,
        saldo: cuenta.saldo,
        cliente_id: cuenta.cliente_id,
        documento: cuenta.documento,
        razon_social: cuenta.razon_social
      }
      this.setEdited(selectedItem);
      this.setSelected(selectedItem);
      this.searchText = selectedItem.razon_social; // Esto asegura que el nombre permanezca visible
    }
  },
  watch: {
    select (newValue) {
      if (newValue) {
        const selectedItem = this.all.find(item => item.id === newValue);
        if (selectedItem) {
          this.setEdited(selectedItem);
          this.setSelected(selectedItem);
          this.searchText = selectedItem.razon_social; // Esto asegura que el nombre permanezca visible
        }
      }
    },
    // selected (val) {
    //   if (val && val.id == null) {
    //       this.onClearEdited();
    //       this.onClearSelected();
    //       this.onClearEditedCliente();
    //       this.onClearSelectedCliente();
    //       this.searchText = '';
    //   }
    // }
  },
}
</script>
