<template>
  <div>
    <v-select
      label="Formas de pago"
      v-model="selectedLocal"
      item-text="nombre"
      item-value="id"
      :items="all"
      prepend-icon="mdi-cash-register"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'FormasPagosSelectComponent',
  model: {
    prop: 'formapagoid',
    event: 'change'
  },
  props: {
    formapagoid: {
      type: Number,
      default: null
    },
  },
  async created () {
    await this.onEstablecerDB();
    await this.onEstablecerDefault();
  },
  computed: {
    ...mapGetters('FormasPagos', ['all']),
    selectedLocal: {
      get () {
        return this.formapagoid
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  methods: {
    ...mapActions('FormasPagos', ['onFetch']),
    ...mapMutations('FormasPagos', ['setAll']),
    async onEstablecerDB () {
      // Verifica si los productos ya están cargados
      if (this.all?.length) return;
      try {
        const aux = this.getFromSession() || await this.onFetch();
        if (aux) this.setAll(aux);
      } catch (error) {
        console.error('Error al cargar las formas de pago:', error);
      }
    },
    onEstablecerDefault () {
      const aux = this.all[0]?.id;
      this.$emit('change', aux)
    },
    getFromSession () {
      const aux = window.sessionStorage.getItem('pag');
      return aux ? JSON.parse(aux) : null;
    }
  }
}
</script>
