<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'DiariosBonosDatatableComponent',
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('DiariosBonos', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    // ...mapGetters('DiariosPagos', ['all']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    },
  },
}
</script>
