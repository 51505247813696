// const URL = '/diarios_pagos';

const state = {
  all: [],
  selected: {
    id: null,
    fecha: null,
    kilos: null,
    importe: null,
    forma_pago_id: null,
    sucursal_id: null,
    user_id: null,
    created_at: null,
    updated_at: null
  },
  edited: {
    id: null,
    fecha: null,
    kilos: null,
    importe: null,
    forma_pago_id: null,
    sucursal_id: null,
    user_id: null,
    created_at: null,
    updated_at: null
  },
  filters: {
    searchText: null,
    fecha_from: null,
    fecha_to: null,
    forma_pago_id: null,
    user_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Forma de pago',
      value: 'forma_pago',
      align: 'center'
    },
    {
      text: 'Total Importe',
      value: 'importe',
      align: 'center'
    },
    {
      text: 'Total KG',
      value: 'kilos',
      align: 'center'
    },
  ],
  rules: {
    fecha: [
      // v => !!v || 'Este campo es necesario',
    ],
    kilos: [
      // v => !!v || 'Este campo es necesario',
    ],
    importe: [
      // v => !!v || 'Este campo es necesario',
    ],
    forma_pago_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    sucursal_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    user_id: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  loading (state) {
    return state.loading
  },
  noData (rootState) {
    return (rootState['DiariosPagos/all'] && rootState['DiariosPagos/all'].length == 0) ||
    (rootState['DiariosBonos/all'] && rootState['DiariosBonos/all'].length == 0)
    ;
  },
}

// ACTIONS
const actions = {
  //
}

// MUTATIONS
const mutations = {
  //
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
