<template>
  <div>    
    <v-select
      label="Tipos"
      v-model="selectedLocal"
      :item-text="(item) => { return item.tecla + '. ' + item.nombre + ' $' + item.precio }"
      item-value="id"
      :items="all"
      prepend-icon="mdi-ticket-confirmation"
      @change="onChangeSelect"
      :messages="messages"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip :key="index">
          <span>{{ item.tecla + '. ' + item.nombre + ' $' + item.precio }}</span>
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'DebitosTiposSelectComponent',
  model: {
    prop: 'debitotipoid',
    event: 'change'
  },
  components: {
    //
  },
  props: {
    debitotipoid: {
      type: Number,
      default: null
    },
    cantidad: {
      type: Number,
      default: 0.0
    },
    dialog: {
      type: Boolean,
      default: false
    },
    messagesShortcut: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      loading: false,
      messages: null,
      precioSelected: 0.0,
    }
  },
  created () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
    this.onFetch()
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    ...mapGetters('DebitosTipos', ['all', 'edited', 'selected']),
    selectedLocal: {
      get () {
        return this.debitotipoid
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    selectedStore: {
      get () {
        return this.selected
      },
      set (value) {
        this.setSelected(value);
      }
    },
    editedStore: {
      get () {
        return this.edited
      },
      set (value) {
        this.setEdited(value);
      }
    },
  },
  watch: {
    cantidad () {
      this.updateImporte();
    },
    messagesShortcut (val) {
      if (val) {
        let aux = this.all.map((item) => {
          return 'Alt+' + item.tecla + ' ' + item.nombre
        });
        this.messages = aux;
      }
      else this.messages = null
    },
  },
  methods: {
    ...mapActions('DebitosTipos', ['onFetch']),
    ...mapMutations('DebitosTipos', ['setEdited', 'setSelected']),
    onChangeSelect (id) {
      const selectedItem = this.all.find(item => item.id === id);
      if (selectedItem && selectedItem.id > 0) {
        this.onChange ({ id: selectedItem.id, precio: selectedItem.precio })
      }
      this.updateImporte();
    },
    onChange ({ id, precio }) {
      this.selectedLocal = id
      this.precioSelected = parseFloat(precio)
    },
    updateImporte() {
      const precioSelected = this.precioSelected || 0.0;
      const cantidad = this.cantidad || 0.0;
      let importe = 0.0;
      if (cantidad > 0 && precioSelected > 0) {
        importe = cantidad * precioSelected;
      }
      // Forzar a que importe tenga 2 decimales
      // importe = parseFloat(importe).toFixed(2);
      // this.$emit('update:importe', importe);
      // Redondear a dos decimales
      importe = Math.round(importe);
      this.$emit('update:importe', importe);
    },
    handleKeyboard (event) {
      // Verifica si Ctrl está presionado y si la tecla es un número del 0 al 9
      if (event.altKey && /^[0-9]$/.test(event.key)) {
        const selectedItem = this.all.find(item => item.tecla === event.key);
        if (selectedItem) {
          event.preventDefault();
          this.onChange ({ id: selectedItem.id, precio: selectedItem.precio })
        }
      }
    },
  }
}
</script>
