import Vue from 'vue';

Vue.filter('formatToTwoDecimals', (value) => {
    if (typeof value !== 'number') return value; // Verifica si es un número
    // Formatear el número a dos decimales y usar '.' como separador de miles
    const parts = value.toFixed(2).split('.'); // Separa en partes antes y después del punto
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Reemplaza los miles con '.'
    return parts.join(','); // Une las partes, usando ',' como separador decimal
});

Vue.filter('formatToThreeDecimals', (value) => {
    const param = parseFloat(value);
    if (typeof param !== 'number') return param; // Verifica si es un número
    // Formatear el número a dos decimales y usar '.' como separador de miles
    const parts = param.toFixed(3).split('.'); // Separa en partes antes y después del punto
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Reemplaza los miles con '.'
    return parts.join(','); // Une las partes, usando ',' como separador decimal
});

Vue.filter('formatToZeroDecimals', (value) => {
    const param = parseFloat(value);
    if (typeof param !== 'number') return param; // Verifica si es un número
    const parts = param.toFixed(2).split('.'); // Separa en partes antes y después del punto
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Reemplaza los miles con '.'
    return parts[0]; // Une las partes, usando ',' como separador decimal
});