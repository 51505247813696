<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
    >
      <!--
      @click:row="onSelect"
      -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Transacciones de la cuenta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="onActualizar"
          >
            <v-icon left>mdi-refresh</v-icon>
            Actualizar
          </v-btn>
        </v-toolbar>
        <transacciones-filtros />
      </template>
      <template v-slot:item.kilos_cantidad="{ item }">
        <div v-if="item.kilos_cantidad && item.kilos_saldo">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-gauge</v-icon> {{ item.kilos_cantidad }}
              </div>
            </template>
            <span>Saldo: {{ item.kilos_saldo }}</span>
          </v-tooltip>
        </div>
        <div v-else-if="item.kilos_cantidad">
          <v-icon small>mdi-gauge</v-icon> {{ item.kilos_cantidad }}
        </div>
      </template>
      <template v-slot:item.kilos_precio="{ item }">
        <div v-if="item.kilos_precio">
          $ {{ item.kilos_precio }}
        </div>
      </template>
      <template v-slot:item.importe="{ item }">
        <div v-if="item.importe">
          $ {{ item.importe }}
        </div>
      </template>
      <template v-slot:item.saldo="{ item }">
        <div v-if="item.saldo">
          $ {{ item.saldo }}
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div
          @click="onSelect(item)"
          v-if="item.transaccion_tipo"
        >
          <v-btn icon>
            <v-icon color="error" v-if="item.transaccion_tipo === 'Debito'">mdi-circle</v-icon>
            <v-icon color="success" v-else>mdi-circle</v-icon>
          </v-btn>
        </div>
        <!--
        <div
          @click="onSelect"
          v-if="item.transaccion_tipo && item.transaccion_tipo === 'Debito'">
          <v-icon color="error">mdi-circle</v-icon>
        </div>
        <div
          @click="onSelect"
          v-else-if="item.transaccion_tipo && item.transaccion_tipo === 'Credito'">
          <v-icon color="success">mdi-circle</v-icon>
        </div>
        -->
      </template>
      <template v-slot:item.fecha="{ item }">
        <div v-if="item.fecha">
          {{ item.fecha | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.transaccion_detalle="{ item }">
        <div v-if="item.transaccion_detalle">
          {{ item.transaccion_detalle }}
        </div>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de transacciones para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <transacciones-edit v-model="dialogEdit" />
    <transacciones-listener />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TransaccionesListener from '@/pages/transacciones/Listener'
import TransaccionesFilters from '@/pages/transacciones/Filters'
import TransaccionesEdit from '@/pages/transacciones/EditComponent'
export default {
  name: 'TransaccionesDatatableComponent',
  components: {
    'transacciones-listener': TransaccionesListener,
    'transacciones-filtros': TransaccionesFilters,
    'transacciones-edit': TransaccionesEdit
  },
  data () {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      dialogEdit: false,
    }
  },
  beforeDestroy () {
    this.onClearAll()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Transacciones', ['all', 'headers', 'loading', 'itemsLength', 'options', 'edited']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    }
  },
  methods: {
    ...mapActions('Transacciones', ['onFetch', 'onClearAll']),
    ...mapMutations('Transacciones', ['setEdited', 'setSelected', 'setOptions']),
    onActualizar (event) {
      event.preventDefault()
      this.onFetch()
    },
    onSelect (item) {
      /* Se cargan los datos en la variable Seleccionado para comparar */
      const aux = {
        ...this.edited,
        id: item.id,
        transaccion_tipo: item.transaccion_tipo,
        transaccion_detalle: item.transaccion_detalle,
        fecha: item.fecha,
        importe: item.importe,
        saldo: item.saldo,
        cuenta_id: item.cuenta_id,
        kilos_cantidad: item.kilos_cantidad,
        kilos_precio: item.kilos_precio,
        kilos_saldo: item.kilos_saldo,
        created_at: item.created_at,
        updated_at: item.updated_at
      }
      this.setEdited(aux)
      this.setSelected(aux)
      this.dialogEdit = true
      // this.$emit('update:selected', true)
    },
  }
}
</script>
