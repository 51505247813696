const URL = '/creditos';

const state = {
  selected: {
    id: null,
    fecha: null,
    cuenta_id: null,
    cantidad: null,
    precio_id: null,
    forma_pago_id: null,
  },
  edited: {
    id: null,
    fecha: null,
    cuenta_id: null,
    cantidad: null,
    precio_id: null,
    forma_pago_id: null,
  },
  loading: false,
  rules: {
    fecha: [
      v => !!v || 'Este campo es necesario',
    ],
    cuenta_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir una cuenta asociada',
    ],
    cantidad: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe ser mayor a cero (0)',
    ],
    precio_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un precio asociado',
    ],
    forma_pago_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir una forma de pago asociada',
    ],
  },
}

// GETTERS
const getters = {
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.rules
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.cuenta_id != state.selected.cuenta_id ||
      state.edited.precio_id != state.selected.precio_id ||
      state.edited.cantidad != state.selected.cantidad ||
      state.edited.fecha != state.selected.fecha ||
      state.edited.forma_pago_id != state.selected.forma_pago_id
    )
  },
}

// ACTIONS
const actions = {
  onClearEdited ({ commit}) {
    commit('setEdited', {
      id: null,
      fecha: null,
      cuenta_id: null,
      cantidad: null,
      // precio_id: null,
      // forma_pago_id: null,
    })
  },
  onClearSelected ({ commit}) {
    commit('setSelected', {
      id: null,
      fecha: null,
      cuenta_id: null,
      cantidad: null,
      // precio_id: null,
      // forma_pago_id: null,
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            fecha: response.data.data.fecha,
            cuenta_id: response.data.data.cuenta_id,
            cantidad: response.data.data.cantidad,
            precio_id: response.data.data.precio_id,
            forma_pago_id: response.data.data.forma_pago_id
          }
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'El credito fue registrado.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se registro el credito', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'El credito fue actualizado.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se actualizo el credito', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'El credito fue borrado.', { root: true })
          dispatch('onClearEdited')
          dispatch('onClearSelected')
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se ha borrado el credito.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
