<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"      
    >
      <v-container>
        <v-text-field
          v-model="numero"
          placeholder="Ingrese el número de cuenta"
          label="Número de cuenta"
          :rules="rules.numero"
          hint="sugerido 4620 3528 3456 9888"
          prepend-icon="mdi-card-account-details"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="descripcion"
          placeholder="Ingrese descripcion de la cuenta"
          label="Descripcion"
          :rules="rules.descripcion"
          prepend-icon="mdi-text"
          color="primary"
        ></v-text-field>
        <!--
        <v-text-field
          value="0.0"
          readonly
          label="Saldo"
          prepend-icon="mdi-currency-usd"
          color="primary"
        ></v-text-field>
        <v-text-field
          v-model="kiloSubsidiados"
          type="number"
          placeholder="Ingrese los kg subsidiados"
          label="KG subsidiados"
          :rules="rules.kilos_subsidiados"
          prepend-icon="mdi-weight-kilogram"
          color="primary"
        ></v-text-field>
        <v-text-field
          :value="kiloSubsidiados"
          readonly
          label="KG disponibles"
          prepend-icon="mdi-weight-kilogram"
          color="primary"
        ></v-text-field>
        -->
        <v-footer>
          <v-alert
            border="left"
            colored-border
            type="warning"
          >
            A continuación, vamos a crear una nueva cuenta con saldo $ 0,00 y {{ kilosSubsidiados }} kg de gas subsidiados.
          </v-alert>
          <v-btn
            :disabled="!updatable"
            color="primary"
            @click="onSubmit"
            :loading="loading"
          >
            Confirmar
          </v-btn>
        </v-footer>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'CuentasCreateComponent',
  data () {
    return {
      valid: false,
      kilosSubsidiados: null
    }
  },
  // beforeMount () {
  //   // Agrega el event listener en el gancho beforeMount
  //   window.addEventListener('keydown', this.handleKeyboard);
  // },
  // beforeDestroy () {
  //   // Remueve el event listener en el gancho beforeDestroy
  //   window.removeEventListener('keydown', this.handleKeyboard);
  //   this.onClearEdited()
  //   this.onClearSelected()
  // },
  created () {
    this.kilosSubsidiados = '240.000'
  },
  computed: {
    ...mapGetters('Cuentas', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    saldo () {
      return this.edited.saldo
    },
    kiloSubsidiados: {
      get () {
        return this.edited.kilos_subsidiados
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_subsidiados: value
        })
      }
    },    
    kiloDisponibles: {
      get () {
        return this.edited.kilos_disponibles
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_disponibles: value
        })
      }
    },    
    switchCierre: {
      get () {
        return this.edited.kilos_disponibles
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_disponibles: value
        })
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Cuentas', ['onSave', 'onFind', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Cuentas', ['setEdited']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(response => {
          this.$emit('change', false)
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    handleKeyboard (event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
    }
  },
}
</script>
