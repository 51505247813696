import Vue from 'vue'
import Vuex from 'vuex'

import Adjuntos from '@/store/modules/Adjuntos'
import Auth from '@/store/modules/Auth'
import Clientes from '@/store/modules/Clientes'
import ClientesDistribuciones from '@/store/modules/ClientesDistribuciones'
import Creditos from '@/store/modules/Creditos'
import Cuentas from '@/store/modules/Cuentas'
import CuentasActivas from '@/store/modules/CuentasActivas'
import CuentasCerradas from '@/store/modules/CuentasCerradas'
import Debitos from '@/store/modules/Debitos'
import FormasPagos from '@/store/modules/FormasPagos'
import Sucursales from '@/store/modules/Sucursales'
import Transacciones from '@/store/modules/Transacciones'
import DebitosTipos from '@/store/modules/DebitosTipos'
import CreditosTipos from '@/store/modules/CreditosTipos'

import ResponseHTTP from '@/store/modules/ResponseHTTP'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Adjuntos,
    Auth,
    Clientes,
    ClientesDistribuciones,
    Creditos,
    CreditosTipos,
    Cuentas,
    CuentasActivas,
    CuentasCerradas,
    Debitos,
    DebitosTipos,
    FormasPagos,
    Sucursales,
    Transacciones,
    ResponseHTTP,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})