<template>
  <div>
    <v-sheet>
      <v-card>
        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="token" class="text-wrap"></v-list-item-title>
              <v-list-item-subtitle>Token de acceso</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="expiresIn"></v-list-item-title>
              <v-list-item-subtitle>Expira en (seg)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn color="success"
            @click.stop="onRefresh"
            :loading="loading"
          >
            Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  computed: {
    token () {
      return window.sessionStorage.getItem('token') || null
    },
    ...mapGetters({
      expiresIn: 'Auth/expiresIn',
      loading: 'Auth/loading',
    }),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    colorToolbar () {
      return this.selected.length ? 'listSelected' : 'toolbarList'
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Auth/onFetch',
      onRefresh: 'Auth/onRefresh',
    }),
  }
}
</script>