<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Exportaciones
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ClientesExportsComponent',
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['loading']),
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', []),
    handleKeyboard(event) {
      // if(event.key === 'Enter' && !event.ctrlKey) {
      //   this.onAplicarFiltros(event)
      // }
      // else if(event.key === "F2" && !event.ctrlKey) {
      //   event.preventDefault()
      //   // this.$router.push({ name: 'clientes-form' })
      // }
      // else if(event.key === "F5" && !event.ctrlKey) {
      //   event.preventDefault()
      //   this.onFetch()
      // }
      // event.preventDefault()
    },
  }
}
</script>
