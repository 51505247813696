export function generateEncabezado(empresa) {
  return {
    alignment: 'justify',
    columns: [
      {
        stack: [
          { text: empresa.razon_social, style: 'subheader' },
          'C.U.I.T.: ' + empresa.cuit,
          'Ing. Brutos: ' + empresa.ing_brutos,
          'Domicilio: ' + empresa.domicilio,
          'Localidad: ' + empresa.localidad,
          'Inicio de Actividades: ' + empresa.actividades,
          'Condicion: ' + empresa.condicion_iva
        ]
      },
      {
        image: 'snow',
        width: 80,
        height: 80
      }
    ]
  };
}
