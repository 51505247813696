<template>
  <div>
    <v-select
      label="Precio"
      v-model="selectedLocal"
      :item-text="(item) => { return item.modo  + ' $ ' + item.importe }"
      item-value="id"
      :items="all"
      prepend-icon="mdi-currency-usd"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip :key="index">
          <span>{{ item.modo + ' $ ' + item.importe }}</span>
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'CreditosPreciosSelectComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    cantidad: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      importe: 0,
    }
  },
  async created () {
    await this.onEstablecerDB();
    await this.onEstablecerDefault();
  },
  mounted() {
    if (this.selected) {
      this.updateImporte(this.selected)
    }
  },
  computed: {
    ...mapGetters('PreciosVigentes', { all: 'creditos' }),
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
        this.updateImporte(value)
      }
    },
  },
  watch: {
    cantidad (newValue) {
      if (newValue && newValue > 0) {
        const aux = newValue * this.importe
        this.$emit('update:importe', aux);
      } else {
        this.$emit('update:importe', 0);
      }
    }
  },
  methods: {
    ...mapMutations('PreciosVigentes', { setAll: 'setCreditos' }),
    updateImporte (selectedId) {
      const selectedItem = this.all.find((item) => item.id === selectedId)
      this.importe = selectedItem ? selectedItem.importe : 0
      this.emitirImporte(this.cantidad)
    },
    emitirImporte(cantidad) {
      const total = cantidad > 0 ? cantidad * this.importe : 0
      this.$emit('update:importe', total)
    },
    async onEstablecerDB () {
      // Verifica si los precios ya están cargados
      if (this.all?.length) return;
      try {
        const aux = this.getFromSession();
        if (aux) this.setAll(aux);
      } catch (error) {
        console.error('Error al cargar los precios:', error);
      }
    },
    onEstablecerDefault () {
      const aux = this.all[0]?.id;
      this.$emit('change', aux)
      this.updateImporte(aux)
    },
    getFromSession () {
      const pro = window.sessionStorage.getItem('cre');
      return pro ? JSON.parse(pro) : null;
    }
  }
}
</script>
