<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark left>mdi-account</v-icon>
          {{ nombre }}
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ nombre }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-email</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ email}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            text
            @click="onSubmitLogout"
          >
            <v-icon left>mdi-power</v-icon>
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }
  },
  created () {
    // this.onEstablecerDB()
    this.onMe()
  },
  computed: {
    ...mapGetters('Auth', ['edited', 'loading']),
    nombre () {
      return this.edited.name
    },
    email () {
      return this.edited.email
    }
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Auth', ['onMe']),
    onSubmitLogout (event) {
      event.preventDefault()
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
