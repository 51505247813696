<template>
  <div>
    <v-card>
      <v-card-text>
        <v-alert
          border="left"
          type="info"
          color="info"
          colored-border
        >
          Esta información es diaria, por ende debe seleccionar una fecha en particular para filtrar los resultados.
        </v-alert>
      </v-card-text>
      <v-card-text>
        <date-picker
          label="Fecha de caja"
          v-model="fecha"
        ></date-picker>
      </v-card-text>
    </v-card>
    <diarios-nuevo
      v-if="noData"
      @created="onCreated"
    ></diarios-nuevo>
    <diarios-pagos></diarios-pagos>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker'
import DiariosPagos from '@/pages/diarios_pagos/DatatableComponent'
import DiariosBonos from '@/pages/diarios_bonos/DatatableComponent'
import DiariosNuevo from '@/pages/diarios/CreateComponent'

export default {
  name: 'DiariosMainComponent',
  components: {
    'date-picker': DatePicker,
    'diarios-pagos': DiariosPagos,
    'diarios-bonos': DiariosBonos,
    'diarios-nuevo': DiariosNuevo
  },
  data () {
    return {
      valid: true,
      attrs: {
        boilerplate: true,
        elevation: 2,
      },
      fecha: null,
    }
  },
  beforeDestroy () {
    //
  },
  beforeMount () {
    this.fecha = this.$moment().format('YYYY-MM-DD')
  },
  created () {
    //
  },
  computed: {
    diariosBonos () {
      return this.$store.getters['DiariosBonos/all'];
    },
    diariosPagos () {
      return this.$store.getters['DiariosPagos/all'];
    },
    noData () {
      console.log('diariosBonos')
      console.log(this.diariosBonos)
      console.log('diariosPagos')
      console.log(this.diariosPagos)
      return (this.diariosPagos.length == 0) && (this.diariosBonos.length == 0)
    },
  },
  watch: {
    fecha (newValue) {
      this.$store.commit('DiariosPagos/setFilters', {
        ...this.$store.getters['DiariosPagos/filters'],
        fecha_from: newValue,
        fecha_to: newValue
      });
      this.$store.commit('DiariosBonos/setFilters', {
        ...this.$store.getters['DiariosBonos/filters'],
        fecha_from: newValue,
        fecha_to: newValue
      });
      this.$store.commit('DiariosCreate/setFecha', newValue);
      this.$store.dispatch('DiariosBonos/onFetch');
      this.$store.dispatch('DiariosPagos/onFetch');
    }
  },
  methods: {
    onCreated () {
      this.$store.dispatch('DiariosBonos/onFetch');
      this.$store.dispatch('DiariosPagos/onFetch');
    }
  },
}
</script>
