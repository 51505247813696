<template>
  <div>
    <v-toolbar
      dark
      color="primary"
      v-if="conversor"
    >
      <v-text-field
        type="number"
        v-model="litros"
        :disabled="disabled"
        placeholder="xxx,xx"
      >
        <template v-slot:prepend>
          <span>Conversor</span>
        </template>
        <template v-slot:append-outer>
          <span>Litros</span>
        </template>
      </v-text-field>
    </v-toolbar>
    <!-- TEXT FIELD FORMATEADO -->
    <v-text-field
      ref="textField"
      v-model="formateado"
      prepend-icon="mdi-gauge"
      :label="label"
      :disabled="disabled"
      :placeholder="placeholder"
      @change="onChange"
      @keydown="onKeydown"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'KilosInputComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: 0.0,
    },
    rules: Array,
    focus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    conversor: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Gas Granel (Kg)'
    },
    placeholder: {
      type: String,
      default: 'Ingrese cantidad de kilos (xxx.xxx,xxx)'
    },
  },
  data () {
    return {
      litros: null,
      formateado: null
    }
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  watch: {
    litros (val) {
      if (val && val > 0) {
        const kilos = parseFloat(val) * 0.51;
        this.onChange(kilos)
      }
      else {
        // alert()
        this.formateado = '0,000'
      }
    },
    focus (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.textField.focus();
        });
      }
    },
    selected (newValue) {
      console.log('watch selected: ' + newValue)
      this.formateado = this.formatearNumero(newValue);
    },
  },
  methods: {
    onChange (event) {
      const inputValue = event;
      if (inputValue && inputValue > 0) {
        this.selectedLocal = inputValue
        this.formateado = this.formatearNumero(inputValue);
      }
    },
    onKeydown (event) {
      const key = event.key;
      const isDigit = key >= '0' && key <= '9';
      const isCommaOrDot = key === ',' || key === '.';
      const isControlKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key); // Teclas de control permitidas
      // Obtén el valor actual del campo de texto
      const currentValue = event.target.value;
      // Verifica si ya hay un punto o una coma en el texto
      const alreadyHasDecimal = currentValue.includes(',') || currentValue.includes('.');
      // Si ya hay un punto o una coma y el usuario intenta ingresar otro, prevenir el evento
      if (isCommaOrDot && alreadyHasDecimal) {
        event.preventDefault();
      }
      // Si no es un dígito, coma/punto permitido o una tecla de control, prevenir el evento
      if (!isDigit && !isCommaOrDot && !isControlKey) {
        event.preventDefault();
      }
    },
    formatearNumero (value) {
      console.log('formatearNumero: ' + value)
      // Asegúrate de que el valor sea un número
      // const numero = parseFloat(value);
      const numero = parseFloat(value.toString().replace(',', '.')); // Reemplaza coma por punto para parsear correctamente
  
      if (isNaN(numero)) return ''; // Si no es un número, retorna una cadena vacía
      const numeroFormateado = numero.toFixed(3); // Devuelve una cadena con 3 decimales
      let [parteEntera, parteDecimal] = numeroFormateado.split('.'); // Divide en parte entera y decimal
      // Formatea la parte entera con separadores de miles
      parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      // Retorna el número formateado con coma como separador decimal
      return `${parteEntera},${parteDecimal}`;
    },
  }
}
</script>
