const URL = '/clientes';

const state = {
  all: [],
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: null,
    created_at: null,
    updated_at: null
  },
  filters: {
    searchText: null,
    documento: null,
    razon_social: null,
    cliente_id: null
  },
  loading: false,
  headers: [
    {
      text: 'Documento',
      value: 'documento',
      align: 'center'
    },
    {
      text: 'Apellido y nombre',
      value: 'razon_social',
      align: 'center'
    }
  ],
  rules: {
    documento: [
      v => !!v || 'Este campo es necesario',
    ],
    razon_social: [
      v => !!v || 'Este campo es necesario',
    ],
    inactivo: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  options: {
    itemsPerPage: 10,
    page: 1,
    sortBy: [],
    sortDesc: []
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  filterable (state) {
    return (state.filters.searchText ||
      state.filters.documento ||
      state.filters.razon_social ||
      state.filters.cliente_id
    )
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearAll ({ state, commit }) {
    commit('setAll', [])
    commit('setItemsLength', null)
    commit('setOptions', {
      ...state.options,
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: []
    })
  },
  onClearSelected ({ state, commit }) {
    commit('setSelected', {
      ...state.selected,
      id: null,
      documento: null,
      razon_social: null,
      inactivo: null,
      created_at: null,
      updated_at: null
    })
  },
  onClearFilters ({ state, commit }) {
    commit('setFilters', {
      ...state.filters,
      searchText: null,
      documento: null,
      razon_social: null,
      cliente_id: null
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          searchText: state.filters.searchText,
          // documento: state.filters.documento,
          // razon_social: state.filters.razon_social,
          // inactivo: state.filters.inactivo,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
