<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark class="primary">
            <v-btn
              icon
              @click="onClose"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Datos del cliente</v-toolbar-title>
          </v-toolbar>
          <v-tabs
            vertical
            v-model="tab"
          >
            <v-tab>
              <v-icon left>mdi-account</v-icon>
              Datos del cliente
            </v-tab>
            <v-tab>
              <v-icon left>mdi-credit-card-plus</v-icon>
              Nueva cuenta
            </v-tab>
            <v-tab>
              <v-icon left>mdi-credit-card-check</v-icon>
              Cuentas Activas
            </v-tab>
            <v-tab>
              <v-icon left>mdi-credit-card-remove</v-icon>
              Cuentas Cerradas
            </v-tab>

            <!-- DATOS DEL CLIENTE -->
            <v-tab-item>
              <v-toolbar flat>
                <v-toolbar-title>Datos del cliente</v-toolbar-title>
              </v-toolbar>
              <v-card flat>
                <v-card-text>
                  <clientes-edit />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- NUEVA CUENTA -->
            <v-tab-item>
              <v-card flat>
                <v-toolbar flat>
                  <v-toolbar-title>Nueva cuenta</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <cuentas-create />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- CUENTAS ACTIVAS -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <cuentas-activas-datatable
                    :cliente-id="clienteId"
                  >
                  </cuentas-activas-datatable>
                  <!-- 
                   -->
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- CUENTAS CERRADAS -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <cuentas-cerradas-datatable
                    :cliente-id="clienteId"
                  >
                  </cuentas-cerradas-datatable>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
            <!--
          <v-sheet>
            <v-toolbar dark class="primary">
              <v-btn
                icon
                @click="onClose"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Datos del credito</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-keyboard</v-icon>
              </v-btn>
            </v-toolbar>-->
            <!--
            <v-navigation-drawer
              permanent
              app
            >
              <v-toolbar class="primary">
              </v-toolbar>
              <v-list nav>
                <v-list-item>
                  <v-list-item-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Datos del cliente</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cuentas Activas</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-credit-card-check</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cuentas Cerradas</v-list-item-title>
                  <v-list-item-icon>
                    <v-icon>mdi-credit-card-remove</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
            -->
            <!-- DATOS CLIENTE -->
            <!-- <clientes-edit />-->

            <!-- CUENTAS ACTIVAS -->
            <!-- <cuentas-activas-datatable />-->
            <!-- CUENTAS CERRADAS -->
            <!-- <cuentas-cerradas-datatable />-->
          <!-- </v-sheet>-->
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CuentasActivasDatatable from '@/pages/cuentas_activas/DatatableComponent'
import CuentasCerradasDatatable from '@/pages/cuentas_cerradas/DatatableComponent'
import CuentasCreate from '@/pages/cuentas/CreateComponent'
import ClientesEdit from '@/pages/clientes/EditComponent'

export default {
  name: 'ClientesFormComponent',
  components: {
    'cuentas-activas-datatable': CuentasActivasDatatable,
    'cuentas-cerradas-datatable': CuentasCerradasDatatable,
    'cuentas-create': CuentasCreate,
    'clientes-edit': ClientesEdit
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      tab: 2,
    }
  },
  beforeDestroy () {
    this.onClearEdited()
    this.onClearSelected()
    this.onClearCuentasCerradas()
    this.onClearCuentasActivas()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['edited']),
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    isSelected () {
      return this.edited.id > 0
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    dialog (newValue) {
      this.$nextTick(() => {
        if (!newValue) {
          this.onClearEdited()
          this.onClearSelected()
          this.onClearCuentasCerradas()
          this.onClearCuentasActivas()
        }
      });
    }
  },
  methods: {
    ...mapActions('Clientes', ['onClearEdited', 'onClearSelected']),
    ...mapActions('CuentasCerradas', { onClearCuentasCerradas: 'onClearAll', onFetchCuentasCerradas: 'onFetch' }),
    ...mapActions('CuentasActivas', { onClearCuentasActivas: 'onClearAll', onFetchCuentasActivas: 'onFetch' }),
    ...mapMutations('Clientes', ['setEdited']),
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    // onDialogOpen () {
    //   alert('onDialogOpen')
    //   this.$nextTick(() => {
    //     if (this.dialog) {
    //       this.onClearEdited()
    //       this.onClearSelected()
    //       this.onClearCuentasCerradas()
    //       this.onClearCuentasActivas()
    //     }
    //   });
    // },
  },
}
</script>
