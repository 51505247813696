<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :readonly="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatted"
          :label="label"
          :placeholder="placeholder"
          :prepend-icon="!hideIcon ? 'mdi-calendar' : ''"
          readonly
          :rules="rules"
          v-bind="attrs"
          v-on="on"
          :clearable="clearable"
          @click:clear="selectedLocal = null"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="selectedLocal"
        header-color="primary"
        locale="es"
        @input="menu = false"
        :readonly="readonly"
        :min="min"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: String,
    label: String,
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    },
    attrs: {
      type: Object,
      default: null
    },
    rules: Array,
    clearable: {
      type: Boolean,
      default: false
    },
    minToday: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    formatted() {
      if (!this.selectedLocal) return ''; // Si no hay fecha seleccionada, muestra vacío
      const [year, month, day] = this.selectedLocal.split('-'); // Divide en partes
      return `${day}/${month}/${year}`; // Convierte a DD/MM/YYYY
    },
    selectedLocal: {
      get() {
        return this.selected; // Devuelve la fecha seleccionada
      },
      set(value) {
        const aux = this.$moment(value).format('YYYY-MM-DD')
        this.$emit('change', aux); // Emite el cambio al padre
      },
    },
    min () {
      return this.minToday ? new Date().toISOString().split('T')[0] : null; // Mínimo en formato YYYY-MM-DD
    },
  },

  watch: {
    readonly(val) {
      this.menu = false; // Cierra el menú si se vuelve de solo lectura
    },
  },
};
</script>
