<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
      @input="onDialogOpen"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos del credito</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-keyboard</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <creditos-tipos-select
              v-model="transaccionTipoId"
              :cantidad="kilosCantidad"
              @update:importe="onUpdateImporte"
              @update:complemento="onUpdateComplemento"
              @update:pagos="onUpdateCreditosTipos"
            ></creditos-tipos-select>
            <v-text-field
              value="Efectivo"
              label="Forma pago"
              prepend-icon="mdi-cash"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="fecha.format('DD/MM/YYYY')"
              readonly
              label="Fecha"
              prepend-icon="mdi-calendar"
              color="primary"
            ></v-text-field>
            <!-- KILO CANTIDAD -->
            <kilos-input
              v-model="kilosCantidad"
              :disabled="transaccionTipoId == null"
              :focus="focusGas"
            ></kilos-input>
            <!--
            <v-text-field
              v-model="kilosCantidad"
              placeholder="Ingrese cantidad de kilos"
              label="Gas Granel"
              type="number"
              :rules="rules.kilos_cantidad"
              :disabled="!(transaccionTipoId > 0)"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            -->
            <v-alert
              border="left"
              colored-border
              type="info"
              v-if="pagos.length > 1"
            >
              {{ pagosSeleccionados }}
            </v-alert>
            <v-text-field
              :value="importe | formatToTwoDecimals"
              label="Importe"
              :rules="rules.importe"
              readonly
              :disabled="!(transaccionTipoId > 0)"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Registrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CreditosTiposSelect from '@/pages/creditos_tipos/SelectComponent'
import KilosInput from '@/components/KilosInput'
export default {
  name: 'CreditosCreateComponent',
  components: {
    'creditos-tipos-select': CreditosTiposSelect,
    'kilos-input': KilosInput
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
      checkbox: true,
      importeSugerido: 0.0,
      focusGas: false,
      complemento: null,
    }
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    this.cuentaId = this.selectedId
    this.fecha = this.$moment()
  },
  computed: {
    ...mapGetters('Cuentas', ['selectedId']),
    ...mapGetters('Creditos', ['edited', 'rules', 'loading', 'pagos', 'updatable']),
    pagosSeleccionados () {
      // Verifica si hay más de un pago en la lista
      if (this.pagosLocal.length > 1) {
        // Devuelve una cadena que concatena los importes formateados con un " + " entre ellos
        return this.pagosLocal.map(pago => {
          return `$ ${Number(pago.importe).toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        }).join(' + ');
      }
      return ''; // Devuelve una cadena vacía si no hay más de un pago
    },
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    id: {
      get () {
        return this.edited.id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          id: value
        })
      }
    },
    fecha: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        return this.edited.fecha ? this.$moment(this.edited.fecha) : this.$moment()
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fecha: this.$moment(value).format('YYYY-MM-DD')
        })
      }
    },
    importe: {
      get () {
        return this.edited.importe
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          importe: parseFloat(value)
        })
      }
    },
    kilosCantidad: {
      get () {
        return parseFloat(this.edited.kilos_cantidad)
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_cantidad: parseFloat(value)
        })
      }
    },
    cuentaId: {
      get () {
        return this.edited.cuenta_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuenta_id: value
        })
      }
    },
    transaccionTipoId: {
      get () {
        return this.edited.transaccion_tipo_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          transaccion_tipo_id: value
        })
      }
    },
    formaPagoId: {
      get () {
        return this.edited.forma_pago_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          forma_pago_id: value
        })
      }
    },
    pagosLocal: {
      get () {
        return this.pagos
      },
      set (value) {
        this.setPagos(value)
      }
    },
  },
  watch: {
    transaccionTipoId (newValue) {
      if (newValue && newValue > 0) {
        this.focusGas = true;
        // Reiniciar después de que el campo gane foco
        this.$nextTick(() => {
          this.focusGas = false;
        });
      }
    },
    selectedId (newValue) {
      this.cuentaId = newValue
    },
    pagosLocal (newValue) {
      // console.log(JSON.stringify(newValue))
    }
  },
  methods: {
    ...mapActions('Cuentas', ['onRefresh']),
    ...mapActions('Transacciones', ['onFetch']),
    ...mapActions('Creditos', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Creditos', ['setEdited', 'setPagos']),
    async onSubmit(event) {
      event.preventDefault();
      this.$refs.form.validate();
      if (this.valid) {
        const importeAux = this.importe;
        const transaccionTipoIdAux = this.transaccionTipoId;
        console.log(typeof this.pagosLocal)
        for (const pago of this.pagosLocal) {
          this.id = null;
          // kilos_precio: null,
          // kilos_saldo: null,
          // complemento: null,
          this.importe = pago.importe;
          this.transaccionTipoId = pago.transaccion_tipo_id;
          try {
            // Esperamos a que se complete onSave antes de continuar
            await this.onSave();
            // Aquí puedes manejar la respuesta si es necesario
            console.log('Pago guardado:', JSON.stringify(pago));
          } catch (error) {
            console.error('Error al guardar el pago:', error);
            break; // Si ocurre un error, podemos decidir romper el ciclo
          }
        }
        this.importe = importeAux
        this.transaccionTipoId = transaccionTipoIdAux
        // Refrescamos los datos después de guardar todos los pagos
        await this.onFetch();
        await this.onRefresh();
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    onUpdateImporte (value) {
      this.importe = value
      this.importeSugerido = value
    },
    onUpdateComplemento (value) {
      this.complemento = value
    },
    onUpdateCreditosTipos (value) {
      // alert(JSON.stringify(value))
      this.pagosLocal = value
    },
    handleKeyboard(event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
      else if(event.altKey && event.key == "s") {
        event.preventDefault()
        this.checkbox = !this.checkbox
      }
      else if(event.altKey && event.key == "k") {
        event.preventDefault()
        this.focusGas = true
      }
    },
    onDialogOpen () {
      // Esperamos a que el diálogo esté completamente abierto
      this.$nextTick(() => {
        if (this.dialog) {
          window.addEventListener('keydown', this.handleKeyboard);
        }
        else {
          window.removeEventListener('keydown', this.handleKeyboard);
          this.importe = 0;
          this.kilosCantidad = 0;
        }
      });
    },
  },
}
</script>
