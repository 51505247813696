<template>
  <div>
    <v-toolbar>
      <v-btn
        icon
        @click="onMenuBuscar"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-text-field
        :value="razonSocial"
        :loading="loading"
        readonly
        class="mx-4"
        flat
        hide-no-data
        hide-details
        solo-inverted
      ></v-text-field>
      <v-btn
        icon
        @click="onMenuUsuario"
      >
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    //
  },
  data () {
    return {
      loading: false,
      search: null,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    ...mapGetters('Cuentas', ['selected']),
    razonSocial () {
      return (this.selected && this.selected.documento && this.selected.razon_social) ?
        this.selected.razon_social + ' | ' + this.selected.documento : ''
    },
  },
  methods: {
    ...mapActions('Cuentas', ['onClearEdited', 'onClearSelected']),
    onMenuUsuario (event) {
      event.preventDefault()
      this.$router.push({ name: 'clientes-form' })
    },
    onMenuBuscar (event) {
      event.preventDefault()
      this.onClearEdited()
      this.onClearSelected()
      // alert('Elimina la cuenta seleccionada y deja disponible para busqueda nueva!')
    },
    handleKeyboard(event) {
      if(event.key === "F4" && !event.ctrlKey) {
        this.onMenuBuscar(event)
      }
    },
  },
}
</script>
