require('./plugins/bootstrap');
require('./plugins/moment');
require('./plugins/permissions');
require('./plugins/firebase');
require('./plugins/numeral');
require('moment/locale/es');
require('./plugins/apexchart');
require('./plugins/format');

import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/plugins/routes'
import store from '@/store/index'
// import { mapGetters, mapActions } from 'vuex'

export default new Vue({
    el: '#app',
    vuetify: vuetify,
    router: router,
    store: store,
    render: h => h(App),
  })