import Vue from 'vue'
import Router from 'vue-router'
// // LAYOUTS
import MainLayout from '@/pages/layouts/Main'
import Login from '@/pages/Login'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import CredencialesSesion from '@/pages/sesion/Credenciales'
import PermisosSesion from '@/pages/sesion/Permisos'
import FormasPagosMain from '@/pages/formas_pagos/Main'
import ProductosMain from '@/pages/productos/Main'
import PreciosVigentesMain from '@/pages/precios_vigentes/Main'
import DiariosMain from '@/pages/diarios/Main'
// CLIENTES
import ClientesMain from '@/pages/clientes/Main'
// CUENTAS
import CuentasMain from '@/pages/cuentas/Main'
import ErrorsHttp from '@/pages/Errors'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/',
      name: 'layout',
      component: MainLayout,
      redirect: 'cuentas/main',
      children: [
        // ####-CUENTAS-####
        {
          path: 'cuentas/main',
          name: 'cuentas-main',
          components: {
            default: CuentasMain
          },
          meta: {
            requiresAuth: true,
            permission: 'cuentas.index',
            title: 'Cuenta'
          }
        },
        // ####-CLIENTES-####
        {
          path: 'clientes/main',
          name: 'clientes-main',
          components: {
            default: ClientesMain
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes'
          }
        },
        // ####-####-####-####
        {
          path: 'formas_pagos/main',
          name: 'formas-pagos-main',
          components: {
            default: FormasPagosMain
          },
          meta: {
            requiresAuth: true,
            permission: 'formas_pagos.index',
            title: 'Formas de pago'
          }
        },
        {
          path: 'productos/main',
          name: 'productos-main',
          components: {
            default: ProductosMain
          },
          meta: {
            requiresAuth: true,
            permission: 'productos.index',
            title: 'Productos'
          }
        },
        {
          path: 'precios_vigentes/main',
          name: 'precios-vigentes-main',
          components: {
            default: PreciosVigentesMain
          },
          meta: {
            requiresAuth: true,
            permission: 'precios.index',
            title: 'Precios vigentes'
          }
        },
        {
          path: 'diarios/main',
          name: 'diarios-main',
          components: {
            default: DiariosMain
          },
          meta: {
            requiresAuth: true,
            permission: 'reportex.index',
            title: 'Cajas diarias'
          }
        },
        // ####-####-####-####
        {
          path: 'sesion/credenciales',
          name: 'sesion-credenciales',
          components: {
            default: CredencialesSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Credenciales',
            color: 'blue'
          }
        },
        {
          path: 'sesion/permisos',
          name: 'sesion-permisos',
          components: {
            default: PermisosSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Permisos',
            color: 'blue'
          }
        },
        // ####-FORBIDDEN-####
        {
          path: '/forbidden',
          name: 'forbidden',
          component: Forbidden,
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.permission) next()
  else {
    const token = window.sessionStorage.getItem('token')
    const permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
    if (permissions.indexOf(to.meta.permission) !== -1) next()
    else if (!token) next({ name: 'login' })
    else next({ name: 'forbidden' })
  }
})

export default router;
