<template>
  <div>
    <v-sheet>
      <v-toolbar flat>
        <v-toolbar-title>Formas de pago</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-list>
          <v-list-item
            v-for="(item, index) in all"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'FormasPagosComponent',
  computed: {
    ...mapGetters('FormasPagos', ['all']),
  },
  async created () {
    await this.onEstablecerDB();
  },
  methods: {
    ...mapActions('FormasPagos', ['onFetch']),
    ...mapMutations('FormasPagos', ['setAll']),
    async onEstablecerDB () {
      // Verifica si los productos ya están cargados
      if (this.all?.length) return;
      try {
        const aux = this.getFromSession();
        if (aux) this.setAll(aux);
      } catch (error) {
        console.error('Error al cargar las formas de pago:', error);
      }
    },
    getFromSession () {
      const aux = window.sessionStorage.getItem('pag');
      return aux ? JSON.parse(aux) : null;
    }
  }
}
</script>
