<template>
  <v-app id="inspire">
    <!--
    <v-main>
    -->
        <v-parallax
          dark
          src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
          height="500"
        >
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-form
                @submit.prevent="onSubmit"
              >
                <v-card class="elevation-4">
                  <v-toolbar
                    color="primary"
                    dark
                  >
                    <v-toolbar-title>Cuentas x cobrar</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      label="Nombre de usuario"
                      name="username"
                      prepend-icon="mdi-account"
                      type="text"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      id="password"
                      label="Contraseña"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="loading"
                    >
                      Entrar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
          </v-container>
        </v-parallax>
    <!--
    </v-main>
    -->
  </v-app>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  export default {
    data () {
      return {
        //
      }
    },
    created () {
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('permissions')
      window.sessionStorage.removeItem('expires_in')
      window.sessionStorage.removeItem('suc')
      window.sessionStorage.removeItem('sucid')
    },
    computed: {
      ...mapGetters('Auth', ['edited', 'errors', 'loading']),
      email: {
        get () {
          return this.edited.email
        },
        set (value) {
          this.setEdited({
            ...this.edited,
            email: value
          })
        },
      },
      password: {
        get () {
          return this.edited.password
        },
        set (value) {
          this.setEdited({
            ...this.edited,
            password: value
          })
        },
      },
    },

    methods: {
      ...mapActions('Auth', ['onLogin', 'onPermisos', 'onMe']),
      ...mapMutations('Auth', ['setEdited']),
      onSubmit () {
        this.onLogin().then(response => {
          this.onPermisos().then(response => {
            this.$router.push({ name: 'cuentas-main' })
          })
        });
      },
      getSessionStorageItem(key) {
        try {
          return JSON.parse(window.sessionStorage.getItem(key))
        } catch (e) {
          return null
        }
      },
      setSessionStorageItem(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
      },
    },
  }
</script>