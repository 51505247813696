const URL = '/precios_vigentes';

const state = {
  debitos: [],
  creditos: [],
  bonos: [],
  loading: false,
}

// GETTERS
const getters = {
  debitos (state) {
    return state.debitos
  },
  creditos (state) {
    return state.creditos
  },
  bonos (state) {
    return state.bonos
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL)
        .then(response => {
          commit('setDebitos', response.data.debito)
          commit('setCreditos', response.data.credito)
          commit('setBonos', response.data.bono)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.nombre, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'El precio fue actualizado.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se actualizo el precio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setDebitos (state, payload) {
    state.debitos = payload.slice()
  },
  setCreditos (state, payload) {
    state.creditos = payload.slice()
  },
  setBonos (state, payload) {
    state.bonos = payload.slice()
  },
  setLoading (state, payload) {
    state.loading = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
