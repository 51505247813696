<template>
  <div>
    <v-app-bar
      color="primary"
      dark
      app
    >
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="onAppBarNav"></v-app-bar-nav-icon>
      <v-toolbar-items>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          :to="{ name: 'clientes-main' }"
        >
          Clientes
        </v-btn>
        <v-btn
          v-else
          text
          :to="{ name: 'clientes-main' }"
        >
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          :to="{ name: 'cuentas-main' }"
        >
          Detalle Cuenta
        </v-btn>
        <v-btn
          v-else
          text
          :to="{ name: 'cuentas-main' }"
        >
          <v-icon>mdi-credit-card</v-icon>
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          text
          :to="{ name: 'diarios-main' }"
        >
          Caja diaria
        </v-btn>
        <v-btn
          v-else
          text
          :to="{ name: 'diarios-main' }"
        >
          <v-icon>mdi-cash-register</v-icon>
        </v-btn>
        <!--
        <v-btn
          text
          :to="{ name: 'facturas-main' }"
        >
          Facturacion
        </v-btn>
        -->
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <sucursales-select></sucursales-select>
      <perfil-usuario></perfil-usuario>
      <menu-adicional></menu-adicional>
    </v-app-bar>

    <v-main>
      <alertas-view></alertas-view>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <v-container>
        <my-forbidden></my-forbidden>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AlertasView from '@/components/AlertasView'
import PermisosRefresh from '@/components/PermisosRefresh'
import Forbidden from '@/components/Forbidden'
import SucursalSelect from '@/pages/sucursales/SelectComponent'
import PerfilUsuario from '@/pages/sesion/Perfil'
import Menu from '@/pages/layouts/Menu'

export default {
  components: {
    'alertas-view': AlertasView,
    'my-permiso-refresh': PermisosRefresh,
    'my-forbidden': Forbidden,
    'sucursales-select': SucursalSelect,
    'perfil-usuario': PerfilUsuario,
    'menu-adicional': Menu,
  },
  data () {
    return {
      //
    }
  },
  created () {
    //
  },
  computed: {
    //
  },
  methods: {
    onAppBarNav (event) {
      event.preventDefault()
      this.$root.$emit('update:appbarnav')
    }
  }
  
}
</script>