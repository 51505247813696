<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
      @input="onDialogOpen"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos del debito</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-keyboard</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-container>
            <debitos-tipos-select
              v-model="debitoTipoId"
              :cantidad="kilosCantidad"
              @update:importe="onUpdateImporte"
            ></debitos-tipos-select>
            <v-text-field
              :value="fecha.format('DD/MM/YYYY')"
              readonly
              label="Fecha"
              prepend-icon="mdi-calendar"
              color="primary"
            ></v-text-field>
            <!-- KILO CANTIDAD -->
            <!-- 
            <v-text-field
              v-model="kilosCantidad"
              placeholder="Ingrese cantidad de kilos"
              label="Gas envasado"
              type="number"
              :rules="rules.kilos_cantidad"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            -->
            <kilos-input
              v-model="kilosCantidad"
              :disabled="debitoTipoId == null"
              :focus="focusGas"
              conversor
            ></kilos-input>
            <v-text-field
              :value="importe | formatToTwoDecimals"
              readonly
              label="Importe"
              :disabled="!(debitoTipoId > 0)"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <!--
            <importes-input
              v-model="importe"
              readonly
              label="Importe"
              :disabled="!(debitoTipoId > 0)"
            ></importes-input>
            -->
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Registrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DebitosTiposSelect from '@/pages/debitos_tipos/SelectComponent'
import KilosInput from '@/components/KilosInput'
import InportesInput from '@/components/ImportesInput'
export default {
  name: 'DebitosCreateComponent',
  components: {
    'debitos-tipos-select': DebitosTiposSelect,
    'kilos-input': KilosInput,
    'importes-input': InportesInput
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
      importeSugerido: 0.0,
      focusGas: false,
    }
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    this.cuentaId = this.selectedId
    this.fecha = this.$moment()
  },
  computed: {
    ...mapGetters('Cuentas', ['selectedId']),
    ...mapGetters('Debitos', ['edited', 'rules', 'loading', 'updatable']),
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    fecha: {
      get () {
        // Verifica si existe una fecha válida en edited.fecha, de lo contrario usa el momento actual.
        return this.edited.fecha ? this.$moment(this.edited.fecha) : this.$moment()
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          fecha: this.$moment(value).format('YYYY-MM-DD')
        })
      }
    },
    importe: {
      get () {
        return this.edited.importe
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          importe: value
        })
      }
    },
    kilosCantidad: {
      get () {
        return parseFloat(this.edited.kilos_cantidad)
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilos_cantidad: parseFloat(value)
        })
      }
    },
    cuentaId: {
      get () {
        return this.edited.cuenta_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuenta_id: value
        })
      }
    },
    debitoTipoId: {
      get () {
        return this.edited.transaccion_tipo_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          transaccion_tipo_id: value
        })
      }
    },
  },
  watch: {
    debitoTipoId (newValue) {
      if (newValue && newValue.id > 0) {
        this.focusGas = true;
        // Reiniciar después de que el campo gane foco
        this.$nextTick(() => {
          this.focusGas = false;
        });
      }
    },
    selectedId (newValue) {
      this.cuentaId = newValue
    },
    kilosCantidad (newValue) {
      // console.log(newValue)
    },
  },
  methods: {
    ...mapActions('Cuentas', ['onRefresh']),
    ...mapActions('Transacciones', ['onFetch']),
    ...mapActions('Debitos', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Debitos', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(response => {
          this.onFetch()
          this.onRefresh()
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    onUpdateImporte (value) {
      this.importe = value
      this.importeSugerido = value
    },
    handleKeyboard (event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
      else if(event.altKey && event.key == "k") {
        event.preventDefault()
        this.focusGas = true
      }
    },
    onDialogOpen () {
      // Esperamos a que el diálogo esté completamente abierto
      this.$nextTick(() => {
        if (this.dialog) {
          window.addEventListener('keydown', this.handleKeyboard);
        }
        else {
          window.removeEventListener('keydown', this.handleKeyboard);
          this.kilosCantidad = 0;
        }
      });
    },
  },
}
</script>
